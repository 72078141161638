import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "organizationId" ]

  initialize() {
    new  App.MD.Combobox(this.organizationIdTarget, {
      placeholder: "Search organizations by name or license number",
      minimumInputLength: 1,
      ajax: {
        delay: 250,
        url: this.organizationIdTarget.getAttribute("data-search-url"),
        dataType: "json"
      },
      templateSelection: this.formatSelection,
      templateResult: this.formatResult
    })
  }

  formatSelection(record) {
    return $(`
      <div class="flex flex-align-left flex-0">
        <div class="">
          ${record.text}
        </div>
      </div>
    `)
  }

  formatResult(record) {
    if (record.loading) {
      return $(`
        <div class="list-item-primary color-helper">
          ${record.text}
        </div>
      `)
    }

    return $(`
      <div class="list-item-primary">
        ${record.name}
        <div class="list-item-primary-subtext">
          ${record.license_number}
        </div>
      </div>
    `)
  }
}
