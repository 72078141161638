import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "checkbox", "digitalSignatureTextField", "submitButton" ]

  initialize() {
    this.toggleDigitalSignatures()
  }

  toggleDigitalSignatures() {
    if (this.checkboxTarget.checked) {
      this.addDigitalSignatures()
      this.submitButtonTarget.disabled = false
    } else {
      this.removeDigitalSignatures()
      this.submitButtonTarget.disabled = true
    }
  }

  addDigitalSignatures() {
    this.digitalSignatureTextFieldTargets.forEach((input) => {
      input.value = input.dataset.name
    })
  }

  removeDigitalSignatures() {
    this.digitalSignatureTextFieldTargets.forEach((input) => {
      input.value = ""
    })
  }
}
