import { Controller } from "@hotwired/stimulus"
import Mousetrap from "mousetrap"
import Mark from "mark.js"

export default class extends Controller {
  static targets = [
    "input",
    "scope",
    "results",
    "result"
  ]

  connect() {
    this.delayInMs = 150
    this.searchDelayTimer = null
    this.selectedElementIndex = null
    this.selectedElement = null

    this.markInstance = null

    // Close dialog by clicking container background (if allowed)
    this.element.addEventListener("click", (event) => {
      if (event.target == this.element) {
        this.hide()
      }
    })

    this.showNoResults()
  }

  focus(_event) {
    this.inputTarget.focus()
  }

  show(event) {
    Global.closeAllDialogs()

    if (this.bottomsheetMenuButton()) {
      this.bottomsheetMenuButton().classList.add("display-none")
    }

    this.element.classList.add("global-search-container-open")
    this.focus()
  }

  hide(event) {
    if (this.bottomsheetMenuButton()) {
      this.bottomsheetMenuButton().classList.remove("display-none")
    }

    this.element.classList.remove("global-search-container-open")
    this.inputTarget.blur()
  }

  attemptSearch(event) {
    this.selectedElementIndex = null
    this.selectedElement = null

    if (this.isQueryPresent()) {
      if (this.isSlashCommand()) {
        this.processSlashCommand()
      } else {
        this.performSearch()
      }
    } else {
      this.showNoResults()
    }
  }

  processSlashCommand() {
    if (!this.isQueryPresent()) { return }
    
    const command = this.query.toLowerCase().split("/")[1]

    switch(command) {
      case "a":
        this.setScope("all")
        break
      case "c":
        this.setScope("contacts")
        break
      case "t":
        this.setScope("transactions")
        break
      case "o":
        this.setScope("organizations")
        break
    }
  }

  setScope(value) {
    const radioButton = Array.from(this.scopeTargets).find((element) => {
      return element.value == value
    })

    if (radioButton) {
      this.inputTarget.value = this.query.split("/")[0]
      radioButton.click()
    }
  }

  clear(event) {
    this.inputTarget.value = ""
    this.focus()
    this.showNoResults()
  }

  updateSelectionWithKeyboard(event) {
    switch (event.key) {
      case "Enter":
        this.activateSelectedElement()
        event.stopPropagation()
        event.preventDefault()
        break
      case "ArrowUp":
        this.selectPrevious()
        event.stopPropagation()
        event.preventDefault()
        break
      case "ArrowDown":
        this.selectNext()
        event.stopPropagation()
        event.preventDefault()
        break
      case "Escape":
        this.hide()
        event.stopPropagation()
        event.preventDefault()
        break
    }
  }

  unselectAllElements() {
    this.resultTargets.forEach((element) => {
      element.setAttribute("data-global-search-selected-element", "false")
    })
  }

  setSelectedElement(elementToSelect) {
    this.unselectAllElements()

    if (elementToSelect == null) {
      this.selectedElement = null
      this.selectedElementIndex = null
      return
    }

    elementToSelect.setAttribute("data-global-search-selected-element", "true")
    elementToSelect.scrollIntoView({ block: "nearest", inline: "nearest" })

    this.selectedElement = elementToSelect
  }

  selectFirst() {
    this.selectedElementIndex = 0
    this.setSelectedElement(this.resultTargets[this.selectedElementIndex])
  }

  selectLast() {
    this.selectedElementIndex = this.resultTargets.length - 1
    this.setSelectedElement(this.resultTargets[this.selectedElementIndex])
  }

  selectPrevious() {
    if (this.selectedElementIndex == null) {
      this.selectedElementIndex = this.resultTargets.length - 1
    } else {
      this.selectedElementIndex--
    }

    if (this.resultTargets[this.selectedElementIndex]) {
      this.setSelectedElement(this.resultTargets[this.selectedElementIndex])
    } else {
      this.selectLast()
    }
  }

  selectNext() {
    if (this.selectedElementIndex == null) {
      this.selectedElementIndex = 0
    } else {
      this.selectedElementIndex++
    }

    if (this.resultTargets[this.selectedElementIndex]) {
      this.setSelectedElement(this.resultTargets[this.selectedElementIndex])
    } else {
      this.selectFirst()
    }
  }

  // If no selected element, do a full-page search with input params
  activateSelectedElement() {
    if (this.selectedElement) {
      this.selectedElement.click()
    }
  }

  performSearch() {
    clearTimeout(this.searchDelayTimer)

    this.searchDelayTimer = setTimeout(() => {
      fetch(`/search/autocomplete?q=${encodeURIComponent(this.query)}&scope=${this.scopeValue}`)
        .then((response) => { return response.text() })
        .then((html) => {
          this.resultsTarget.innerHTML = html

          this.selectFirst()

          this.markInstance = new Mark(this.resultTargets)

          this.markInstance.mark(this.query, {
            separateWordSearch: false,
            exclude: [".list-item-icon", ".material-icons", ".button-icon", ".skip-markjs"]
          })
        })
        .catch((error) => {
          console.error(error)
        })
    }, this.delayInMs)
  }

  showNoResults() {
    // this.resultsTarget.innerHTML = ""
    this.resultsTarget.innerHTML = `
      <div class="list-item global-search-result no-hover">
        <div class="list-item-icon">
        </div>
        <div class="list-item-primary color-helper">
          <strong>No results</strong>
          <div class="list-item-primary-subtext">
            No records match this search criteria
          </div>
        </div>
      </div>
    `
  }

  bottomsheetMenuButton() {
    return document.querySelector(".bottomsheet-menu-button")
  }

  get query() {
    return this.inputTarget.value.trim()
  }

  isQueryPresent() {
    return this.query.length > 0
  }

  isSlashCommand() {
    return (this.isQueryPresent() && this.query.includes("/"))
  }

  get scopeValue() {
    const radioButton = Array.from(this.scopeTargets).find((element) => {
      return element.checked
    })

    return radioButton.value
  }
}
