import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "list",
    "searchButton",
    "search",
    "searchInput",
    "comment"
  ]

  connect() {
    this.markInstance = null

    this.hideSearch()
  }

  toggleSearch(event) {
    if (!this.hasSearchTarget) { return }
    
    if (this.searchTarget.hidden) {
      this.searchButtonTargets.forEach((element) => {
        element.classList.toggle("color-blue")
      })
      this.showSearch()
      this.searchInputTarget.focus()
    } else {
      this.searchButtonTargets.forEach((element) => {
        element.classList.toggle("color-blue")
      })
      this.hideSearch()
      this.showAllComments()
    }
  }

  hideSearch() {
    if (this.hasSearchTarget) {
      this.searchTarget.hidden = true
    }
  }

  showSearch() {
    if (this.hasSearchTarget) {
      this.searchTarget.hidden = false
    }
  }

  unmarkComments() {
    if (this.markInstance) {
      this.markInstance.unmark()
    }
  }

  showAllComments() {
    this.unmarkComments()

    this.commentTargets.forEach((element) => {
      element.hidden = false
    })
  }

  search(event) {
    const searchText = this.searchInputTarget.value.trim()

    if (searchText == null || searchText == "") {
      this.showAllComments()
      return
    }

    this.markInstance = new Mark(this.commentTargets)

    this.unmarkComments()

    this.markInstance.mark(searchText, {
      separateWordSearch: false,
      exclude: [".button-icon", ".event-actions a", ".material-icons"],
      done: () => {
        this.commentTargets.forEach((element) => {
          if (element.querySelector("mark")) {
            element.hidden = false
          } else {
            element.hidden = true
          }
        })
      }
    })
  }
}
