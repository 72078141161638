import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "select",
    "weekly",
    "monthly"
  ]

  connect() {
    this.update()
  }

  update(_event) {
    switch (this.getSelectedValue()) {
      case "day":
        this.weeklyTarget.hidden = true
        this.monthlyTarget.hidden = true

        break
      case "week":
        this.monthlyTarget.hidden = true
        this.weeklyTarget.hidden = false

        break
      case "month":
        this.weeklyTarget.hidden = true
        this.monthlyTarget.hidden = false

        break
    }
  }

  getSelectedValue() {
    const value =  this.selectTarget.value

    if (value) {
      return value.trim()
    } else {
      "day" // Default
    }
  }

  disconnect() {}
}
