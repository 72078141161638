import { Controller } from "@hotwired/stimulus"
import HtmlEditor from "components/html_editor"

export default class extends Controller {
  static targets = [
    "creditFreezeEmailReplyToUserIds",
    "intakeDisclosuresBody",
    "creditFreezeEmailBody",
    "test",
    "thankYouEmailBody"
  ]

  connect() {
    $(this.creditFreezeEmailReplyToUserIdsTarget).select2({
      allowClear: true,
      minimumResultsForSearch: Infinity
    })

    new HtmlEditor(this.intakeDisclosuresBodyTarget, {
      heightMin: 100
    })

    new HtmlEditor(this.creditFreezeEmailBodyTarget, {
      heightMin: 100
    })

    new HtmlEditor(this.thankYouEmailBodyTarget, {
      heightMin: 100
    })
  }
}
