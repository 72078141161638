import { Controller } from "@hotwired/stimulus"
import HtmlEditor from "components/html_editor"

export default class extends Controller {
  static targets = [ "signature" ]

  initialize() {
    new HtmlEditor(this.signatureTarget, {
      placeholderText: this.signatureTarget.getAttribute("data-default-placeholder")
    })

    Masks.init()
  }
}
