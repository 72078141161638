import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "menu" ]
  static values = {
    direction: String,
    horizontalDirection: String,
    verticalDirection: String,
    exposed: Boolean
  }

  connect() {
    this.element.querySelectorAll(".menu-item").forEach((element) => {
      if (!element.classList.contains("menu-item-disabled")) {
        element.addEventListener("click", (event) => {
          this._hide()
        })
      }
    })

    // When clicking disabled element, it would fall back to click the actual
    // link `.menu-item`.
    this.element.querySelectorAll(".menu-item-disabled").forEach((disabledElement) => {
      disabledElement.addEventListener("click", (event) => {
        event.preventDefault()
        event.stopPropagation()
        return false
      })
    })

    // Default
    if (!this.hasDirectionValue) {
      this.directionValue = "bottom-right"
    }

    this.verticalDirectionValue = this.directionValue.split("-")[0]
    this.horizontalDirectionValue = this.directionValue.split("-")[1]
  }

  toggle(event) {
    event.stopPropagation()
    event.preventDefault()

    if (this.visible) {
      this._hide()
    } else {
      this.hideAllMenus()
      this.positionMenu(event)
      this._show()
    }
  }

  // Restrict to visible window
  positionMenu(event) {
    let targetDimensions = event.currentTarget.getBoundingClientRect()

    // bottom, height, left, right, top, width, x, y

    if (this.horizontalDirectionValue == "right") {
      this.menuTarget.style.right = "auto"
      this.menuTarget.style.left = `${targetDimensions.left}px`

      if ((this.menuTarget.offsetLeft + this.menuTarget.offsetWidth) > window.innerWidth) {
        this.menuTarget.style.left = "auto"
        this.menuTarget.style.right = this.borderOffset
      }
    } else {
      this.menuTarget.style.left = "auto"
      this.menuTarget.style.right = `${window.innerWidth - targetDimensions.right}px`

      if (this.menuTarget.offsetLeft < 0) {
        this.menuTarget.style.right = "auto"
        this.menuTarget.style.left = this.borderOffset
      }
    }

    if (this.verticalDirectionValue == "bottom") {
      this.menuTarget.style.bottom = "auto"

      if (this.exposedValue) {
        this.menuTarget.style.top = `${targetDimensions.bottom + this.offset}px`
      } else {
        this.menuTarget.style.top = `${targetDimensions.top}px`
      }

      if ((this.menuTarget.offsetTop + this.menuTarget.offsetHeight) > window.innerHeight) {
        this.menuTarget.style.top = "auto"
        this.menuTarget.style.bottom = this.borderOffset
      }
    } else {
      this.menuTarget.style.top = "auto"

      if (this.exposedValue) {
        this.menuTarget.style.bottom = `${window.innerHeight - targetDimensions.top - this.offset}px`
      } else {
        this.menuTarget.style.bottom = `${window.innerHeight - targetDimensions.bottom}px`
      }

      if (this.menuTarget.offsetTop < 0) {
        this.menuTarget.style.bottom = "auto"
        this.menuTarget.style.top = this.borderOffset
      }
    }
  }

  hideAllMenus() {
    document.querySelectorAll(".menu").forEach((element) => {
      element.classList.remove("menu-open")
    })
  }

  hide(event) {
    if (this.element.contains(event.target) === false && this.visible) {
      this._hide()
    }
  }

  _show() {
    this.menuTarget.classList.add("menu-open")
  }

  _hide() {
    this.menuTarget.classList.remove("menu-open")
  }

  get visible() {
    return this.menuTarget.classList.contains("menu-open")
  }

  // Space between menu and trigger element if exposed
  get offset() {
    return 2
  }

  // Space between menu and screen edge
  get borderOffset() {
    return "4px"
  }

  // calculateOpeningDirection() {
  //   let topOrBottom = (this.menuTarget.getAttribute("data-position") || "top-right").split("-")[0]
  //   let leftOrRight = (this.menuTarget.getAttribute("data-position") || "top-right").split("-")[1]

  //   let windowBottom = window.innerHeight
  //   let windowRight = window.innerWidth

  //   let menuBottom = this.menuTarget.getBoundingClientRect().top + this.menuTarget.offsetHeight
  //   let menuRight = this.menuTarget.getBoundingClientRect().left + this.menuTarget.offsetWidth

  //   if (menuBottom > windowBottom) {
  //     topOrBottom = "bottom"
  //   }

  //   if (menuRight > windowRight) {
  //     leftOrRight = "right"
  //   }

  //   let menuPosition = `${topOrBottom}-${leftOrRight}`
  //   let styles = {}

  //   // Set the menu position styles to actually show up in visible window
  //   switch (menuPosition) {
  //     case "top-right":
  //       styles = {
  //         left: "auto",
  //         right: 0,
  //         top: 0,
  //         bottom: "auto",
  //         transformOrigin: "100% 0"
  //       }
  //       break
  //     case "top-left":
  //       styles = {
  //         left: 0,
  //         right: "auto",
  //         top: 0,
  //         bottom: "auto",
  //         transformOrigin: "0 0"
  //       }
  //       break
  //     case "bottom-left":
  //       styles = {
  //         left: 0,
  //         right: "auto",
  //         top: "auto",
  //         bottom: 0,
  //         transformOrigin: "0 100%"
  //       }
  //       break
  //     case "bottom-right":
  //       styles = {
  //         left: "auto",
  //         right: 0,
  //         top: "auto",
  //         bottom: 0,
  //         transformOrigin: "100% 100%"
  //       }
  //       break
  //   }

  //   this.menuTarget.style.left = styles["left"]
  //   this.menuTarget.style.right = styles["right"]
  //   this.menuTarget.style.top = styles["top"]
  //   this.menuTarget.style.bottom = styles["bottom"]
  //   this.menuTarget.style.transformOrigin = styles["transformOrigin"]

  //   // Correct for future clicks
  //   this.menuTarget.setAttribute("data-position", menuPosition)
  // }
}
