import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "salaryOption",
    "hourlyOption",
    "annualBaseSalaryField",
    "hourlyRateField"
  ]

  // Once, when controller first instantiated
  initialize() {
    if (this.salaryOptionTarget.checked) {
      this.showSalary()
    } else if (this.hourlyOptionTarget.checked) {
      this.showHourly()
    }
  }

  showSalary() {
    this.annualBaseSalaryFieldTarget.style.display = ""
    this.hourlyRateFieldTarget.style.display = "none"
  }

  showHourly() {
    this.annualBaseSalaryFieldTarget.style.display = "none"
    this.hourlyRateFieldTarget.style.display = ""
  }
}
