import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = [
    "toggleReadButton"
  ]

  static values = {
    status: String,
    uuid: String
  }

  click(event) {
    if (this.statusValue == "unread") {
      this.markAsRead(event)
    }
  }

  updateToggleReadButton() {
    if (this.statusValue == "read") {
      this.toggleReadButtonTarget.innerHTML = "radio_button_unchecked"
      this.toggleReadButtonTarget.classList.remove("color-red")
      this.toggleReadButtonTarget.setAttribute("data-tooltip", "Mark as unread")
    } else {
      this.toggleReadButtonTarget.innerHTML = "radio_button_checked"
      this.toggleReadButtonTarget.classList.add("color-red")
      this.toggleReadButtonTarget.setAttribute("data-tooltip", "Mark as read")
    }
  }

  toggleRead(event) {
    event.preventDefault()
    event.stopPropagation()

    if (this.statusValue == "read") {
      this.markAsUnread(event)
    } else {
      this.markAsRead(event)
    }

    this.element.focus()
  }

  statusValueChanged() {
    if (this.statusValue == "read") {
      this.element.classList.remove("notification-unread")
    } else {
      this.element.classList.add("notification-unread")
    }

    this.updateToggleReadButton()
  }

  markAsRead(event) {
    this.statusValue = "read"

    Rails.ajax({
      url: `/notifications/${this.uuidValue}/mark_as_read`,
      type: "PUT"
    })
  }

  markAsUnread(event) {
    this.statusValue = "unread"

    Rails.ajax({
      url: `/notifications/${this.uuidValue}/mark_as_unread`,
      type: "PUT"
    })
  }
}
