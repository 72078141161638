import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = [ "notification" ]

  markAllAsRead(event) {
    this.notificationTargets.forEach((element) => {
      element.setAttribute("data-notification-status-value", "read")
    })

    Rails.ajax({
      url: "/notifications/mark_all_as_read",
      type: "PUT"
    })
  }
}
