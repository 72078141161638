export default class Global {
  // Retrieve StimulusJS controller object
  static stimulusController(element, controllerName) {
    return window
           .Stimulus
           .getControllerForElementAndIdentifier(element, controllerName)
  }

  // Uses `history.back()` to handle back button
  static closeDialog(idOrElement = "") {
    const element = (idOrElement instanceof Element) ? idOrElement : document.getElementById(idOrElement.replace("#", ""))

    if (element != null) {
      Global.stimulusController(element, "dialog").close()
    }
  }

  // Removes dialog element without using the `history.back()`
  static removeDialog(idOrElement = "") {
    const element = (idOrElement instanceof Element) ? idOrElement : document.getElementById(idOrElement.replace("#", ""))

    if (element != null) {
      Global.stimulusController(element, "dialog").remove()
    }
  }

  static closeAllDialogs() {
    document.querySelectorAll("[data-controller~='dialog']").forEach((element, _index) => {
      this.removeDialog(element)
    })
  }
}
