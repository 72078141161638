//======================================================================
// Attach to an input and it will automatically select value on focus.
//======================================================================
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.element.addEventListener("focus", this.onFocus)
  }

  disconnect() {
    this.element.removeEventListener("focus", this.onFocus)
  }

  onFocus = (event) => {
    this.element.select()
  }
}
