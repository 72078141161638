import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = [ "form", "globalStage", "accountStage" ]

  initialize() {
  }

  submit() {
    let data = Rails.serializeElement(this.formTarget)

    Rails.ajax({
      url: `/transaction_records/${this.transactionRecordUuid()}/stage`,
      type: "PUT",
      data: data
    })
  }

  select(event) {
    let inputElement = event.target

    if (this.transactionRecordUuid() != undefined) {
      let data = {
        global_stage_uuid: inputElement.getAttribute("data-global-stage-uuid"),
        account_stage_uuid: inputElement.getAttribute("data-account-stage-uuid")
      }

      let params = []
      for (let key in data) {
        if (data[key] == null) {
          data[key] = ""
        }
        params.push(`${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
      }

      Rails.ajax({
        url: `/transaction_records/${this.transactionRecordUuid()}/stage`,
        type: "PUT",
        data: params.join("&")
      })
    }
  }

  transactionRecordUuid() {
    return this.formTarget.getAttribute("data-transaction-record-uuid")
  }
}
