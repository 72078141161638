export default class Snackbar {
  static create(text, options = {}) {
    // Do nothing if no text
    if (!text) { return }

    this.removeAnyExistingSnackbars()

    this.text = text
    this.timeoutInMs = options["timeoutInMs"] || 5000
    this.element = this.createElement()

    this.element.addEventListener("click", () => {
      this.hide()
    })

    if (this.timeout) {
      clearTimeout(this.timeout)
    }

    requestAnimationFrame(() => {
      this.element.classList.add("snackbar-container-visible")
    })

    this.timeout = setTimeout((() => {
      return this.hide()
    }), this.timeoutInMs)
  }

  static hide() {
    requestAnimationFrame(() => {
      this.element.classList.remove("snackbar-container-visible")
    })

    setTimeout((() => {
      this.element.remove()
    }), 500)
  }

  static createElement() {
    const element = document.createElement("div")
    element.classList.add("snackbar-container")

    element.innerHTML = `
      <div class="snackbar">
        <div class="snackbar-primary">
          ${this.text}
        </div>
      </div>
    `

    document.body.appendChild(element)
    return element
  }

  static removeAnyExistingSnackbars() {
    document.querySelectorAll(".snackbar-container").forEach((element) => {
      requestAnimationFrame(() => {
        element.classList.remove("snackbar-container-visible")
      })

      setTimeout((() => {
        element.remove()
      }), 500)
    })
  }
}
