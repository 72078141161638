import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "selectedCount",
    "maxCount",
    "checkbox"
  ]

  static values = {
    maxAllowed: Number
  }

  connect() {
    this.maxCountTarget.innerHTML = this.maxAllowedValue
  }

  toggle(event) {
    const checkbox = event.currentTarget

    const checkedCount = this.getCheckedCheckboxesCount()

    this.selectedCountTarget.innerHTML = checkedCount

    if (checkedCount >= this.maxAllowedValue) {
      this.disableUncheckedCheckboxes()
    } else {
      this.enableUncheckedCheckboxes()
    }
  }

  disableUncheckedCheckboxes() {
    this.checkboxTargets.forEach((element) => {
      if (!element.checked) {
        element.closest(".list-item").classList.add("list-item-disabled")
      }
    })
  }

  enableUncheckedCheckboxes() {
    this.checkboxTargets.forEach((element) => {
      if (!element.checked) {
        element.closest(".list-item").classList.remove("list-item-disabled")
      }
    })
  }

  getCheckedCheckboxesCount() {
    const checked = this.checkboxTargets.filter((element) => {
      return element.checked
    })

    return checked.length
  }
}
