import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.element.setAttribute("novalidate", true)
    this.element.addEventListener("blur", this.onBlur, true)
    this.element.addEventListener("submit", this.onSubmit)
    this.element.addEventListener("ajax:beforeSend", this.onSubmit)
  }

  disconnect() {
    this.element.removeEventListener("blur", this.onBlur)
    this.element.removeEventListener("submit", this.onSubmit)
    this.element.removeEventListener("ajax:beforeSend", this.onSubmit)
  }

  onBlur = (event) => {
    // Only validate field on blur if it has an error.
    // Don't want to show error msgs just by tabbing, only after submission.
    if (event.target.classList.contains("invalid")) {
      this.validateField(event.target)
    }
  }

  onSubmit = (event) => {
    // Only run when forms are submitted, not when remote links are clicked.
    if (event.target.tagName.toLowerCase() === "form") {
      if (!this.validateForm()) {
        event.preventDefault()
        this.firstInvalidField.focus()
      }
    }
  }

  validateForm() {
    let isValid = true
    // Not using `find` because we want to validate all the fields
    this.formFields.forEach((field) => {
      if (this.shouldValidateField(field) && !this.validateField(field)) {
        isValid = false
      }
    })
    return isValid
  }

  validateField(field) {
    if (!this.shouldValidateField(field)) {
      return true
    }
    const isValid = field.checkValidity()
    field.classList.toggle("invalid", !isValid)
    this.refreshErrorForInvalidField(field, isValid)
    return isValid
  }

  shouldValidateField(field) {
    return !field.disabled && !["file", "reset", "submit", "button"].includes(field.type)
  }

  refreshErrorForInvalidField(field, isValid) {
    this.removeExistingErrorMessage(field)
    if (!isValid) {
      this.showErrorForInvalidField(field)
    }
  }

  removeExistingErrorMessage(field) {
    const id = `${field.getAttribute("id")}-error-message`
    const existingErrorMessageElement = document.getElementById(id)
    if (existingErrorMessageElement) {
      existingErrorMessageElement.parentNode.removeChild(existingErrorMessageElement)
    }
  }

  showErrorForInvalidField(field) {
    field.insertAdjacentHTML("afterend", this.buildFieldErrorHtml(field))
  }

  buildFieldErrorHtml(field) {
    const fieldId = field.getAttribute("id")
    return `<div class="error-message" id="${fieldId}-error-message">${field.validationMessage.replace(".", "")}</div>`
  }

  get formFields() {
    return Array.from(this.element.elements)
  }

  get firstInvalidField() {
    return this.formFields.find((field) => {
      return !field.checkValidity()
    })
  }
}
