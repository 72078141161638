import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "primaryBorrowerClientId",
    "closingDate",
    "rateLockExpirationDate",
    "loanToValuePercentage",
    "approvalDate",
    "addressVerification",
    "addressVerificationList",
    "addressVerificationIcon",
    "addressVerificationText",
    "unlockButton",
    "addressField",
    "addressLine1",
    "addressLine2",
    "city",
    "state",
    "zip"
  ]

  initialize() {
    const controller = this

    new Datepicker(this.closingDateTarget, { position: "bottom right" })
    new Datepicker(this.approvalDateTarget)
    new Datepicker(this.rateLockExpirationDateTarget)

    // Masks.init()
    // Masks.currency(document.getElementById("transaction_record_approved_loan_amount_in_dollars"))

    // Masks.percent(this.loanToValuePercentageTarget)
    Masks.init()

    // Client autocomplete for new transactions
    if (this.hasPrimaryBorrowerClientIdTarget) {
      let $primaryBorrowerClientId = $(this.primaryBorrowerClientIdTarget)

      new  App.MD.Combobox($primaryBorrowerClientId, {
        placeholder: "Search clients by first and last names...",
        minimumInputLength: 1,
        ajax: {
          delay: 250,
          url: $primaryBorrowerClientId.attr("data-search-url"),
        }
      })
    }

    this.updateVerification()

    this.addressFieldTargets.forEach(function(field) {
      field.addEventListener("input", function(event) {
        if (event.keyCode != 13) {
          controller.addressVerificationTarget.value = "unverified"
          controller.updateVerification()
        }
      })
    })

    $(this.addressLine1Target).autoComplete({
      source: function(text, responseCallback) {
        text = text.toLowerCase()

        const apiKey = document.querySelector("meta[name='address-autocomplete-api-key']").content

        $.ajax({
          type: "POST",
          dataType: "json",
          url: "https://api.addressy.com/Capture/Interactive/Find/v1.1/json3.ws",
          data: {
            Key: apiKey,
            Text: text,
            IsMiddleware: false,
            Countries: "US",
            Language: "en-us"
          },
          success: function(data) {
            let matches = data.Items.filter(function(record) {
              return record.Type === "Address"
            })

            responseCallback(matches)
          }
        })
      },
      minChars: 1,
      limit: 3,
      delay: 150,
      cache: false,
      menuClass: "list",
      renderItem: function(item, search) {
        search = search.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&")
        let regex = new RegExp(`(${search.split(" ").join("|")})`, "gi")

        return `
          <div class="autocomplete-suggestion list-item list-two-lines" data-id="${item.Id}" data-text="${item.Text}" data-description="${item.Description}" data-value="${item.Text}">
            <div class="list-item-primary">
              ${item.Text.replace(regex, "<b>$1</b>")},
              ${item.Description.replace(regex, "<b>$1</b>")}
            </div>
          </div>
        `
      },
      onSelect: function(event, term, item) {
        controller.addressLine1Target.value = item.data("text")

        let cityStateZip = controller.splitIntoAddressParts(item.data("description"))

        controller.cityTarget.value = cityStateZip.city
        controller.stateTarget.value = cityStateZip.state
        controller.zipTarget.value = cityStateZip.zip

        event.preventDefault()
        event.stopPropagation()

        controller.addressVerificationTarget.value = "verified"
        controller.updateVerification()
      }
    })
  }

  // The data is returned from the API in format
  // "city state zip" ("Los Angelos CA 12345").
  splitIntoAddressParts(combinedCityStateZip) {
    combinedCityStateZip = combinedCityStateZip.split(" ")
    let zip = combinedCityStateZip.pop()
    let state = combinedCityStateZip.pop()
    let city = combinedCityStateZip.join(" ")

    return {
      city: city,
      state: state,
      zip: zip
    }
  }

  updateVerification() {
    switch(this.addressVerificationTarget.value) {
      case "unverified":
        this.addressVerificationTextTarget.innerHTML = "Unverified"
        this.addressVerificationIconTarget.innerHTML = "error"
        this.addressVerificationIconTarget.classList = "material-icons color-amber"
        this.enableAddressFields()
        this.unlockButtonTarget.style.display = "none"
        break
      case "verified":
        this.addressVerificationTextTarget.innerHTML = "Verified"
        this.addressVerificationIconTarget.innerHTML = "check_circle"
        this.addressVerificationIconTarget.classList = "material-icons color-green"
        this.disableAddressFields()
        this.unlockButtonTarget.style.display = "inline-flex"
        break
    }
  }

  unlockAddress(event) {
    this.unlockButtonTarget.style.display = "none"
    this.enableAddressFields()
    this.addressLine1Target.focus()
  }

  enableAddressFields() {
    this.addressFieldTargets.forEach(function(field) {
      field.readOnly = false
    })
  }

  disableAddressFields() {
    this.addressFieldTargets.forEach(function(field) {
      field.readOnly = true
    })
  }
}
