import { Controller } from "@hotwired/stimulus"
import Mark from "mark.js"

export default class extends Controller {
  static targets = [
    "hidden",
    "input",
    "icon",
    "option",
    "options"
  ]

  static values = {
    url: String
  }

  setupIcon() {
    if (this.hasIconTarget) {
      this.inputTarget.style.paddingRight = "44px"

      this.iconTarget.style.position = "absolute"
      this.iconTarget.style.top = "0"
      this.iconTarget.style.right = "2px"
      this.iconTarget.style.width = "40px"
      this.iconTarget.style.height = "40px"
      this.iconTarget.style.textAlign = "center"

      this.iconTarget.classList.add("color-text", "inline-flex", "flex-align-center", "flex-vertical-align-center")
    }
  }

  updateIcon() {
    if (!this.hasIconTarget) { return }

    if (this.hiddenTarget.value.length > 0) {
      this.iconTarget.innerHTML = "check"
      this.iconTarget.classList.add("color-green")
      this.iconTarget.title = "Selected"
    } else {
      this.iconTarget.innerHTML = "arrow_drop_down"
      this.iconTarget.classList.remove("color-green")
      this.iconTarget.classList.add("color-text")
      this.iconTarget.title = "Nothing selected"
    }
  }

  setInputTargetValue(value) {
    this.inputTarget.value = value
  }

  setEmptyText() {
    this.optionsTarget.innerHTML = `
      <li class="list-item no-hover" role="option" aria-disabled="true">
        <div class="list-item-primary color-helper">
          ${this.placeholderText}
        </div>
      </li>
    `
  }

  connect() {
    this.searchDelayTimer = null
    this.selectedIndex = null

    this.placeholderText = this.inputTarget.getAttribute("data-placeholder") || "Search..."

    this.forceHide()
    this.setupIcon()

    // Used to highlight/mark matched text in results
    this.markInstance = new Mark(this.optionsTarget)

    // Set display text from selected text
    this.setInputTargetValue(this.hiddenTarget.getAttribute("data-text"))

    this.inputTarget.setAttribute("autocomplete", "off")
    this.inputTarget.setAttribute("spellcheck", "false")
    this.inputTarget.setAttribute("aria-haspopup", "true")
    this.inputTarget.setAttribute("aria-autocomplete", "list")
    this.inputTarget.setAttribute("role", "combobox")
    this.inputTarget.setAttribute("aria-expanded", "false")

    // this.inputTarget.addEventListener("click", (event) => {
    //   this.show(event)
    // })

    this.inputTarget.addEventListener("keydown", (event) => {
      this.updateSelectionWithKeyboard(event)
    })

    this.inputTarget.addEventListener("input", (event) => {
      this.attemptSearch(event)
    })

    this.optionsTarget.setAttribute("role", "listbox")

    this.updateIcon()

    this.setEmptyText()
  }

  // Because options container is using `position: fixed`, have to
  // update the width to match parent whenever screen resizes.
  resizeOptionsContainer() {
    const dimensions = this.inputTarget.getBoundingClientRect()
    this.optionsTarget.style.width = `${dimensions["width"]}px`
    this.optionsTarget.style.top = `${dimensions["bottom"]}px`
  }

  show(event) {
    this.resizeOptionsContainer()
    this.inputTarget.setAttribute("aria-expanded", true)
    this.optionsTarget.hidden = false
  }

  // External HTML call, hides if clicking outside of the element
  hide(event) {
    if (this.element.contains(event.target) === false) {
      this.forceHide()
    }
  }

  forceHide() {
    this.inputTarget.setAttribute("aria-expanded", false)
    this.optionsTarget.hidden = true

    this.setEmptyText()

    // Reset display text to actual selected text
    this.setInputTargetValue(this.hiddenTarget.getAttribute("data-text"))
  }

  get query() {
    return this.inputTarget.value.trim()
  }

  unselectAllOptions() {
    this.optionTargets.forEach((element) => {
      element.setAttribute("aria-selected", false)
    })
  }

  // External event for HTML to call
  select(event) {
    this.unselectAllOptions()
    this.selectOption(event.currentTarget)
  }

  selectOption(optionToSelect) {
    if (optionToSelect) {
      optionToSelect.setAttribute("aria-selected", true)

      this.hiddenTarget.value = optionToSelect.getAttribute("data-value")
      this.hiddenTarget.setAttribute("data-text", optionToSelect.getAttribute("data-text"))

      this.dispatchChangeEvent()

      this.inputTarget.value = optionToSelect.getAttribute("data-text")

      this.inputTarget.classList.remove("invalid")
      this.updateIcon()

      this.forceHide()
      this.inputTarget.focus()
    }
  }

  highlightOption(optionToHighlight) {
    if (optionToHighlight == null) {
      this.selectedIndex = null
      return
    }

    this.unselectAllOptions()

    // Styles use this attribute to highlight
    optionToHighlight.setAttribute("aria-selected", true)
  }

  // Highlight result when you hover over it
  mouseover(event) {
    const optionToHighlight = event.currentTarget
    this.selectedIndex = this.optionTargets.indexOf(optionToHighlight)
    this.highlightOption(optionToHighlight)
  }

  attemptSearch(event) {
    // Changing text, so remove currently-selected value
    if (this.hiddenTarget.value.length) {
      this.hiddenTarget.value = null
      this.hiddenTarget.setAttribute("data-text", "")
      this.dispatchChangeEvent()
    }
    
    this.updateIcon()

    if (this.query.length > 0) {
      this.selectedIndex = null

      this.performSearch()
    } else {
      this.setEmptyText()
      // this.forceHide()
    }
  }

  performSearch() {
    if (!this.hasUrlValue) { return }

    clearTimeout(this.searchDelayTimer)

    this.searchDelayTimer = setTimeout(() => {
      fetch(`${this.urlValue}?q=${this.query}`)
        .then((response) => { return response.text() })
        .then((html) => {
          this.optionsTarget.innerHTML = html

          this.selectFirstOption()

          this.markInstance = new Mark(this.optionTargets)

          this.markInstance.mark(this.query, {
            separateWordSearch: false,
            exclude: [".list-item-icon", ".material-icons", ".button-icon", ".skip-markjs"]
          })

          this.show()
        })
        .catch((error) => {
          console.error(error)
          this.forceHide()
        })
    }, 150)
  }

  updateSelectionWithKeyboard(event) {
    switch (event.key) {
      case "Enter":
        if (!this.optionsTarget.hidden) {
          this.selectOption(this.optionTargets[this.selectedIndex])

          event.stopPropagation()
          event.preventDefault()
        }

        break
      case "ArrowUp":
        this.selectPreviousOption()

        event.stopPropagation()
        event.preventDefault()
        break
      case "ArrowDown":
        this.selectNextOption()

        event.stopPropagation()
        event.preventDefault()
        break
      case "Escape":
        this.forceHide()

        event.stopPropagation()
        event.preventDefault()
        break
      case "Tab":
        this.forceHide()

        break
    }
  }

  selectPreviousOption() {
    this.selectedIndex--

    const optionToSelect = this.optionTargets[this.selectedIndex]

    if (optionToSelect) {
      this.highlightOption(optionToSelect)
      optionToSelect.scrollIntoView(false)
    } else {
      this.selectLastOption()
    }
  }

  selectNextOption() {
    this.selectedIndex++

    const optionToSelect = this.optionTargets[this.selectedIndex]

    if (optionToSelect) {
      this.highlightOption(optionToSelect)
      optionToSelect.scrollIntoView(false)
    } else {
      this.selectFirstOption()
    }
  }

  selectFirstOption() {
    this.selectedIndex = 0
    this.highlightOption(this.optionTargets[0])

    if (this.optionTargets[0]) {
      this.optionTargets[0].scrollIntoView(false)
    }
  }

  selectLastOption() {
    this.selectedIndex = this.optionTargets.length - 1
    this.highlightOption(this.optionTargets[this.selectedIndex])

    if (this.optionTargets[this.selectedIndex]) {
      this.optionTargets[this.selectedIndex].scrollIntoView(false)
    }
  }

  dispatchChangeEvent() {
    const changeEvent = new Event("change")
    this.hiddenTarget.dispatchEvent(changeEvent)
  }
}
