import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "form", "listId", "contactId" ]

  initialize() {
    if (this.hasContactIdTarget) {
      new  App.MD.Combobox(this.contactIdTarget, {
        minimumInputLength: 1,
        maximumSelectionLength: Infinity,
        placeholder: "Add contacts...",
        ajax: {
          delay: 250,
          url: this.contactIdTarget.getAttribute("data-search-url"),
          dataType: "json"
        },
        templateResult: this.formatResult
      })

      this.contactIdTarget.focus()
    }

    if (this.hasListIdTarget) {
      new  App.MD.Combobox(this.listIdTarget, { placeholder: "Add to list" })

      this.listIdTarget.focus()
    }
  }
}
