import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "emailTemplateIdField",
    "emailTemplateName",
    "removeEmailTemplateButton"
  ]
  
  connect() {
    if (this.emailTemplateIdFieldTarget.value.length > 0) {
      this.showRemoveButton()
      this.disableFields()
    } else {
      this.hideRemoveButton()
      this.enableFields()
    }
  }

  emailTemplateIdFieldValueChanged(newValue) {
    console.log(this.emailTemplateIdFieldValue.length)
    if (this.emailTemplateIdFieldValue.length > 0) {
      this.disableFields()
      this.showRemoveButton()
    } else {
      this.enableFields()
      this.hideRemoveButton()
    }
  }

  showRemoveButton() {
    this.removeEmailTemplateButtonTarget.hidden = false
  }

  hideRemoveButton() {
    this.removeEmailTemplateButtonTarget.hidden = true
  }

  removeEmailTemplate(event) {
    event.preventDefault()
    event.stopPropagation()

    this.emailTemplateNameTarget.innerHTML = "No email template"
    this.emailTemplateIdFieldTarget.value = ""

    this.removeEmailTemplateButtonTarget.closest(".list-item").classList.toggle("bg-highlight")

    this.hideRemoveButton()
    this.enableFields()
  }

  disableFields() {
    document.getElementById("email_notification_email_branding_id").disabled = true
    document.getElementById("email_notification_subject").setAttribute("data-email-subject-disabled-value", true)
    document.getElementById("email_notification_body").setAttribute("data-html-editor-disabled-value", true)  
  }

  enableFields() {
    document.getElementById("email_notification_email_branding_id").disabled = false
    document.getElementById("email_notification_subject").setAttribute("data-email-subject-disabled-value", false)
    document.getElementById("email_notification_body").setAttribute("data-html-editor-disabled-value", false)  
  }
}