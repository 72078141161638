import Tribute from "tributejs"

export default class Placeholders {
  constructor(placeholderVariableType) {
    this.placeholderVariableType = placeholderVariableType

    const tribute = new Tribute({
      trigger: "@",
      values: [],
      lookup: "name",
      fillAttr: "name",
      spaceSelectsMatch: true,
      replaceTextSuffix: "",

      noMatchTemplate: function() {
        return `
          <ul>
            <li class="tribute-no-match-found">No match found</li>
          </ul>
        `
      },

      // List of options
      menuItemTemplate: function(item) {
        return item.string
      },

      // What gets inserted
      selectTemplate: (item) => {
        if (this.placeholderVariableType == "mention") {
          return `<span class="mention tribute-mention fr-deletable" contenteditable="false" data-behavior="mention" data-uuid="${item.original.uuid}">@${item.original.name}</span>`
        } else {
          return `<span class="placeholder-variable fr-deletable fr-atwho fr-tribute" contenteditable="false" data-behavior="placeholder-variable" data-slug="${item.original.name}">${item.original.name}</span>`
        }
      }
    })

    if (this.placeholderVariableType) {
      fetch(this.dataUrl(), {
        headers: { accept: "application/json" }
      })
      .then((response) => {
        return response.json()
      })
      .then((data) => {
        tribute.append(0, data)
      })
    }

    return tribute
  }

  dataUrl() {
    if (this.placeholderVariableType) {
      switch(this.placeholderVariableType) {
        case "message":
          return "/autocompletables/placeholders/messages"
          break
        case "mention":
          return "/mentions"
          break
        case "lead":
          return "/autocompletables/placeholders/lead_emails"
          break
        case "workflow":
          return "/autocompletables/placeholders/email_notifications"
          break
        case "emailNotification":
          return "/autocompletables/placeholders/email_notifications"
          break
        case "textNotification":
          return "/autocompletables/placeholders/text_notifications"
          break
      }
    }
  }
}
