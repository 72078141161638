import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "input",
    "button"
  ]

  connect() {
    this.inputTarget.style.paddingRight = "48px"

    this.buttonTarget.style.position = "absolute"
    this.buttonTarget.style.right = "2px"
    this.buttonTarget.style.top = "2px"
    this.buttonTarget.style.width = "36px"
    this.buttonTarget.style.height = "36px"

    // Default is hidden password
    this.buttonTarget.setAttribute("title", "Show password")
  }

  toggle(event) {
    if (this.inputTarget.getAttribute("type") === "password") {
      this.inputTarget.setAttribute("type", "text")

      this.buttonTarget.innerHTML = "visibility_off"
      this.buttonTarget.setAttribute("title", "Hide password")
    } else {
      this.inputTarget.setAttribute("type", "password")

      this.buttonTarget.innerHTML = "visibility"
      this.buttonTarget.setAttribute("title", "Show password")
    }

    this.inputTarget.focus()
  }
}
