import { Controller } from "@hotwired/stimulus"
import Mark from "mark.js"

export default class extends Controller {
  static targets = [
    "searchInput",
    "options",
    "option"
  ]

  static values = {
    selectedIndex: { type: Number, default: -1 }
  }

  connect() {
    this.markInstance = new Mark(this.optionsTarget)

    this.searchInputTarget.focus()
  }

  // Remove all text highligting in options
  unmarkAllOptions() {
    if (this.markInstance) {
      this.markInstance.unmark(this.optionsTarget)
    }
  }

  showAllOptions() {
    this.optionTargets.forEach((element) => {
      element.hidden = false
    })
  }

  deselectAllOptions() {

  }

  selectOption(option) {
    if (option == null) { return }

    this.optionTargets.forEach((element) => {
      element.setAttribute("aria-selected", false)
      element.setAttribute("data-selected", false)
    })

    option.setAttribute("aria-selected", true)
    option.setAttribute("data-selected", true)

    option.scrollIntoView({ block: "nearest", inline: "nearest" })
  }

  selectPrevious() {
    this.selectedIndexValue--
  }

  selectNext() {
    this.selectedIndexValue++
  }

  commitSelected() {
    const option = this.selectedOption
    option.click()
  }

  updateSelectionWithKeyboard(event) {
    switch (event.key) {
      case "Enter":
        this.commitSelected()
        break
      case "ArrowUp":
        this.selectPrevious()
        break
      case "ArrowDown":
        this.selectNext()
        break
    }
  }

  filter() {
    this.unmarkAllOptions()
    this.showAllOptions()

    if (this.searchValue.length > 0) {
      this.markInstance.mark(this.searchValue, {
        separateWordSearch: true,
        exclude: [".list-item-icon *", ".material-icons *", ".skip-markjs *"],
        done: () => {
          this.optionTargets.forEach((element) => {
            if (element.querySelector("mark") == null) {
              element.hidden = true
            }
          })

          // this.selectOption(this.visibleOptions[0])
          this.selectedIndexValue = -1
        }
      })
    }
  }

  selectedIndexValueChanged(newValue, oldValue) {
    if (newValue == -1) {
      this.selectedIndexValue = 0
    } else {
      if (this.selectedIndexValue >= this.visibleOptions.length) {
        // this.selectOption(this.visibleOptions[0])
        this.selectedIndexValue = 0
      } else {
        this.selectOption(this.visibleOptions[newValue])
      }
    }
  }

  get searchValue() {
    return this.searchInputTarget.value.trim()
  }

  // `:scope` restricts to only direct children of root.
  get visibleOptions() {
    return Array.from(this.optionsTarget.querySelectorAll(":scope > :not([hidden])"))
  }

  get selectedOption() {
    return this.visibleOptions[this.selectedIndexValue]
  }
}
