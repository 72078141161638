import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "rentalOption", "secondHomeOption", "landOption",
    "rentalField", "secondHomeField", "landField"
  ]

  // Once, when controller first instantiated
  initialize() {
    if (this.rentalOptionTarget.checked) {
      this.showRental()
    } else if (this.secondHomeOptionTarget.checked) {
      this.showSecondHome()
    } else {
      this.showLand()
    }
  }

  showRental() {
    this.secondHomeFieldTargets.forEach((field) => {
      field.style.display = "none"
    })

    this.rentalFieldTargets.forEach((field) => {
      field.style.display = ""
    })
  }

  showSecondHome() {
    this.rentalFieldTargets.forEach((field) => {
      field.style.display = "none"
    })

    this.secondHomeFieldTargets.forEach((field) => {
      field.style.display = ""
    })
  }

  showLand() {
    this.rentalFieldTargets.forEach((field) => {
      field.style.display = "none"
    })

    this.secondHomeFieldTargets.forEach((field) => {
      field.style.display = "none"
    })
  }
}
