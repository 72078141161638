import { Controller } from "@hotwired/stimulus"
import consumer from "channels/consumer"
import Rails from "@rails/ujs"
import Mark from "mark.js"

export default class extends Controller {
  static targets = [
    "searchIcon",
    "search",
    "searchInput",
    "messages",
    "message",
    "form",
    "newBody",
    "submitButton"
  ]

  static values = {
    uuid: String
  }

  connect() {
    this.markInstance = null
    this.searchTarget.hidden = true
    this.scrollToBottom()

    if (this.hasFormTarget) {
      this.submitButtonTarget.disabled = true
      this.newBodyTarget.focus()

      // Submit via ENTER
      this.newBodyTarget.addEventListener("keydown", (event) => {
        if (event.key == "Enter") {
          event.preventDefault()
          event.stopPropagation()

          if (this.newBodyTarget.value.length > 0) {
            // this.formTarget.submit()
            Rails.fire(this.formTarget, "submit")
            this.resetForm()

            this.submitButtonTarget.disabled = true
          }
        }
      })

      // Disable/enable form submission and button
      this.newBodyTarget.addEventListener("input", (event) => {
        const text = this.newBodyTarget.value.replace(/ /g, "")

        if (text.length > 0) {
          this.submitButtonTarget.disabled = false
        } else {
          this.submitButtonTarget.disabled = true
        }
      })

      this.subscription = consumer.subscriptions.create({
        channel: "ConversationChannel",
        uuid: this.uuidValue
      }, {
        connected: this._connected.bind(this),
        disconnected: this._disconnected.bind(this),
        received: this._received.bind(this)
      })
    }
  }

  disconnect() {
    if (this.hasFormTarget) {
      consumer.subscriptions.remove(this.subscription)
    }
  }

  resetForm(event) {
    this.newBodyTarget.value = ""
    this.newBodyTarget.style.height = "inherit"
    this.newBodyTarget.focus()
    this.submitButtonTarget.disabled = true
  }

  toggleSearch(event) {
    if (this.searchTarget.hidden) {
      this.searchIconTarget.classList.add("color-blue")
      this.searchTarget.hidden = false
      this.searchInputTarget.focus()
    } else {
      this.searchIconTarget.classList.remove("color-blue")
      this.searchTarget.hidden = true

      this.showAllMessages()
    }
  }

  unmarkMessages() {
    if (this.markInstance) {
      this.markInstance.unmark()
    }
  }

  showAllMessages() {
    this.unmarkMessages()

    this.messageTargets.forEach((element) => {
      element.hidden = false
    })

    // this.scrollToBottom()
  }

  search(event) {
    const searchText = this.searchInputTarget.value.trim()

    if (searchText == null || searchText == "") {
      this.showAllMessages()
      return
    }

    this.markInstance = new Mark(this.messageTargets)

    this.unmarkMessages()

    this.markInstance.mark(searchText, {
      separateWordSearch: false,
      done: () => {
        this.messageTargets.forEach((element) => {
          if (element.querySelector("mark")) {
            element.hidden = false
          } else {
            element.hidden = true
          }
        })
      }
    })
  }

  _connected() {}

  _disconnected() {}

  _received(data) {
    if (data.conversation_uuid) {
      document.querySelectorAll(`[data-domid='conversation_${data.conversation_uuid}']`).forEach((element) => {
        element.classList.remove("conversation-unresolved")
      })

      let resolveButtonElement = document.createRange().createContextualFragment(data.resolve_button)
      document.querySelectorAll(`[data-attribute='conversation_${data.conversation_uuid}.resolve_button_component']`).forEach((element) => {
        element.replaceWith(resolveButtonElement)
      })
    }

    if (data.message) {
      this.messagesTarget.insertAdjacentHTML("beforeend", data.message)
      this.scrollToBottom()
    }
  }

  scrollToBottom(_event) {
    this.messagesTarget.scrollTop = this.messagesTarget.scrollHeight
  }
}
