import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "checkbox", "summary", "details" ]

  connect() {
    if (this.hasCheckboxTarget) {
      if (this.checkboxTarget.checked) {
        this.show()
      } else {
        this.hide()
      }
    }
  }

  toggle(event) {
    if (this.detailsTarget.hidden) {
      this.show()
    } else {
      this.hide()
    }
  }

  show() {
    this.detailsTarget.hidden = false
  }

  hide() {
    this.detailsTarget.hidden = true
  }

  disconnect() {}
}
