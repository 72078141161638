import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "assistant" ]

  initialize() {
    new  App.MD.Combobox(this.assistantTarget, {
      placeholder: "Search partners by first and last names...",
      minimumInputLength: 1,
      ajax: {
        delay: 250,
        url: this.assistantTarget.getAttribute("data-search-url")
      }
    })
  }
}
