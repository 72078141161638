import { Controller } from "@hotwired/stimulus"
import consumer from "channels/consumer"

export default class extends Controller {
  static values = {
    unresolvedTextNotificationsCount: Number
  }

  connect() {
    this.subscription = consumer.subscriptions.create({
      channel: "CurrentUserChannel",
      sgid: this.sgid
    }, {
      connected: this._connected.bind(this),
      disconnected: this._disconnected.bind(this),
      received: this._received.bind(this)
    })
  }

  disconnect() {
    consumer.subscriptions.remove(this.subscription)
  }

  _connected() {}

  _disconnected() {
    consumer.subscriptions.remove(this.subscription)
  }

  _received(data) {
    if (data.mention_notifications_count != null) {
      let count = data.mention_notifications_count
      document.getElementById("mention-notifications-count").setAttribute("data-count", count)

      // TODO: figure out how to make this work properly and get user permission
      // for Notifications from the settings page.

      // if (!("Notification" in window)) {
      //   console.error("Browser does not support desktop notifications")
      // } else if (Notification.permission === "granted") {
      //   new Notification("Mentioned in a comment", {
      //     body: data.message
      //   })
      // } else if (Notification.permission !== "denied") {
      //   Notification.requestPermission().then((permission) => {
      //     if (permission === "granted") {
      //       new Notification("Mentioned in a comment", {
      //         body: data.message
      //       })
      //     }
      //   })
      // }
    }

    // Broadcast from: `conversation_message#send_broadcasts`
    if (data.unresolved_text_conversation_notifications_count != null) {
      let count = data.unresolved_text_conversation_notifications_count

      if (count == "increment") {
        this.unresolvedTextNotificationsCountValue++
      } else if (count == "decrement") {
        this.unresolvedTextNotificationsCountValue--
      } else {
        this.unresolvedTextNotificationsCountValue = count
      }
    }
  }

  unresolvedTextNotificationsCountValueChanged() {
    if (this.unresolvedTextNotificationsCountValue < 0) {
      this.unresolvedTextNotificationsCountValue = 0
    }
    document.getElementById("unresolved-text-conversation-notifications-count").setAttribute("data-count", this.unresolvedTextNotificationsCountValue)
  }
}
