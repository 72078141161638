import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"

// const application = Application.start()
// const context = require.context("controllers", true, /_controller\.js$/)
// application.load(definitionsFromContext(context))

// // Allow access in server JS
// window.stimulusApplication = application

window.Stimulus = Application.start()
const context = require.context("controllers", true, /_controller\.js$/)
Stimulus.load(definitionsFromContext(context))