import { Controller } from "@hotwired/stimulus"
import HtmlEditor from "components/html_editor"

export default class extends Controller {
  static targets = [ "headerBody", "footerBody" ]

  initialize() {
    new HtmlEditor(this.headerBodyTarget, {
      placeholderText: "Header",
      heightMin: 100
    })
    new HtmlEditor(this.footerBodyTarget, {
      placeholderText: "Footer",
      heightMin: 100
    })
  }
}
