//======================================================================
// Attach to a `text_area` input and it will autoexpand the height.
//======================================================================
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    maxHeight: Number
  }

  connect() {
    this.element.addEventListener("input", this.onResize.bind(this))
    this.onResize()
  }

  disconnect() {
    this.element.removeEventListener("input", this.onResize.bind(this))
  }

  onResize(_event) {
    this.element.style.height = "inherit"

    if (this.maxHeightValue && this.element.scrollHeight > this.maxHeightValue) {
      this.element.style.height = `${this.maxHeightValue}px`
    } else {
      this.element.style.height = `${this.element.scrollHeight + 2}px`
    }
  }

  get value() {
    return this.element.value
  }
}
