import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "toolbar", "content" ]
  static values = {
    scrollTo: String
  }

  // Whenever a new `data-controller="dialog"` elment is added to the page
  connect() {
    this.dialogId = this.element.getAttribute("id")

    // Don't allow duplicates of same dialog
    if (this.dialogAlreadyExists()) {
      console.log("Duplicate dialog")
      this.element.remove()
      return
    }

    // Close dialog by clicking container background (if allowed)
    if (this.element.getAttribute("data-closable") === "true") {
      this.element.addEventListener("click", (event) => {
        if (event.target == this.element) {
          this.close()
        }
      })
    }

    // Automatically show after event handlers attached
    this.show()

    // Automatically focus on `autofocus="true"` form fields (if any)
    // const autofocusElement = this.element.querySelector("input[autofocus]")
    // if (autofocusElement !== null) {
    //   autofocusElement.focus()
    // }

    // Shadow if content scrolls
    // TODO: might want to throttle `scroll` event for optimization
    // if (this.hasToolbarTarget && this.hasContentTarget) {
    //   this.contentTarget.addEventListener("scroll", ((event) => {
    //     if (this.contentTarget.scrollTop > 0) {
    //       this.toolbarTarget.classList.add("scroll-shadow-active")
    //     } else {
    //       this.toolbarTarget.classList.remove("scroll-shadow-active")
    //     }
    //   }))
    // }

    if (this.hasScrollToValue) {
      let comment = document.getElementById(this.scrollToValue)

      if (comment) {
        comment.scrollIntoView()
        comment.classList.add("bg-highlight")
      }
    }
  }

  // Don't show the same dialog multiple times
  dialogAlreadyExists() {
    // `this.dialogId` set in `connect()`
    const dialogCount = document.querySelectorAll(`#${this.dialogId}`).length

    return dialogCount > 1
  }

  show(event) {
    // Use CSS animation by stopping the browser from optimizing
    requestAnimationFrame(() => {
      this.element.classList.add("dialog-container-open")
    })

    // TODO: Cannot get this working; Turbolinks always take over `popstate`
    // Update history so that `popstate` event can handle back button
    window.history.replaceState({ dialog: { id: this.dialogId } }, null, window.location)
    window.history.pushState({ dialog: { id: this.dialogId } }, null, window.location)
  }

  hide() {
    // Use CSS animation by stopping the browser from optimizing
    requestAnimationFrame(() => {
      this.element.classList.remove("dialog-container-open")
    })
  }

  close(event) {
    // Don't close when clicking dialog itself
    if (event && event.target && event.target.parentElement && event.target.parentElement.matches(".dialog")) {
      return
    }

    history.back()
  }

  remove(event) {
    requestAnimationFrame(() => {
      this.element.classList.remove("dialog-container-open")
    })

    window.setTimeout(() => {
      this.element.remove()
      // history.back()
    }, 500)
  }
}
