import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "select",
    "textField"
  ]

  initialize() {
    this.update()
  }

  update(event) {
    const value = String(this.selectTarget.value).toLowerCase()
    if (value == "other") {
      this.textFieldTarget.style.display = "block"
    } else {
      this.textFieldTarget.style.display = "none"
      this.textFieldTarget.value = ""
    }
  }
}
