import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "summary", "details" ]

  // Once, when controller first instantiated
  initialize() {
    let state = this.element.getAttribute("data-expansion-panel-state")

    if (state == "expanded") {
      this.showDetails()
    } else {
      this.showSummary()
    }
  }

  showSummary(event) {
    this.element.setAttribute("data-expansion-panel-state", "collapsed")
    this.summaryTarget.style.display = "block"
    this.detailsTarget.style.display = "none"
  }

  showDetails(event) {
    this.element.setAttribute("data-expansion-panel-state", "expanded")
    this.summaryTarget.style.display = "none"
    this.detailsTarget.style.display = "block"
  }
}
