export default class BinaryInsert {
  static add(listSelector, html, options = {}) {
    if (!listSelector) { throw new Error("Must provide a listSelector") }
    if (!html) { throw new Error("Must provide content to insert") }

    this.sortKey = options["sortKey"] || "data-sort"
    this.listSelector = listSelector
    this.element = document.createRange().createContextualFragment(html).firstElementChild
    this.elementSortValue = this.element.getAttribute(this.sortKey)

    this.list = this.getListElement()

    // Break if list does not exist on page
    if (!this.list) {
      return
    }
    // If using reverse chronological order (newest at top)
    this.reverse = (options["reverse"] === true)

    console.log(this.reverse)

    this.children = Array.from(this.list.children).filter((item) => {
      return item.hasAttribute(this.sortKey)
    })

    this.childrenValues = this.children.map((e) => {
      return e.getAttribute(this.sortKey)
    })

    this.listLength = this.children.length

    // Edge cases for optimizations
    //----------------------------------------------------------------------

    // Empty list, make new element first one
    if (this.listLength <= 0) {
      console.log("empty list")
      this.list.prepend(this.element)
      return
    }

    // New element does not have a sortable value, add to the bottom
    if (!this.elementSortValue) {
      console.log("new element does not have sortable value")
      this.list.append(this.element)
      return
    }

    if (this.reverse) {
      // Greater than start element, so prepend to list
      if (this.elementSortValue > this.childrenValues[0]) {
        console.log("(reverse) greater than start element")
        this.list.prepend(this.element)
        return
      }
    } else {
      // Less than start element, so prepend to list
      if (this.elementSortValue < this.childrenValues[0]) {
        console.log("less than start element")
        this.list.prepend(this.element)
        return
      }
    }

    if (this.reverse) {
      // Less than last element, append to list
      if (this.elementSortValue < this.childrenValues[this.listLength - 1]) {
        console.log("(reverse) less than last element")
        this.list.append(this.element)
        return
      }
    } else {
      // Greater than last element, append to list
      if (this.elementSortValue > this.childrenValues[this.listLength - 1]) {
        console.log("greater than last element")
        this.list.append(this.element)
        return
      }
    }

    // Binary algorithm
    //----------------------------------------------------------------------

    let startIndex = 0
    let endIndex = this.childrenValues.length - 1
    let middleIndex = startIndex + Math.floor((endIndex - startIndex) / 2)

    console.log("EndIndex ", endIndex)

    while (startIndex <= endIndex) {
      middleIndex = startIndex + Math.floor((endIndex - startIndex) / 2)

      if (this.elementSortValue < this.childrenValues[middleIndex]) {
        endIndex = middleIndex - 1
        continue
      }

      startIndex = middleIndex + 1
      if (this.childrenValues[middleIndex] === this.elementSortValue) {
        break
      }
    }

    if (endIndex <= 0) {
      startIndex = 0
    }

    // Insert element into proper position
    if (this.reverse) {
      // TODO: still working on this...
      this.children.at(-startIndex + 1).insertAdjacentElement("afterend", this.element)
    } else {
      this.children[startIndex].insertAdjacentElement("beforebegin", this.element)
    }
  }

  static getListElement() {
    return document.querySelector(this.listSelector)
  }
}
