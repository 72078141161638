import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = []

  initialize() {

  }

  select(event) {
    const quickmenuElement = document.getElementById("quickmenu-dialog")
    window.Global.stimulusController(quickmenuElement, "dialog").close()
  }
}
