import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "closingDate" ]

  initialize() {
    new Datepicker(this.closingDateTarget, {
      bound: false,
      container: document.getElementById("transaction_record_closing_date_formatted_container"),
      enableSelectionDaysInNextAndPreviousMonths: true
    })

    $(this.closingDateTarget).inputmask("mm/dd/yyyy", { placeholder: "__/__/____" })
  }
}
