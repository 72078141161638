import { Controller } from "@hotwired/stimulus"
import Snackbar from "classes/snackbar"

export default class extends Controller {
  static targets = []
  static values = {
    text: String,
    confirmation: String
  }

  copy(event) {
    event.preventDefault()
    event.stopPropagation()

    if (this.textValue == null || this.textValue == "") {
      Snackbar.create("Nothing to copy")
    } else if (navigator.clipboard.writeText(this.textValue)) {
      Snackbar.create(this.getConfirmationText())
    } else {
      Snackbar.create("Unable to use copy API, use CTRL/CMD+C instead")
    }
  }

  getConfirmationText() {
    if (this.confirmationValue) {
      return this.confirmationValue
    } else {
      return `Copied to clipboard (${this.textValue})`
    }
  }
}
