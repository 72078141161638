import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "overlay",
    "leftSidebar",
    "globalSearch"
  ]

  initialize() {
    this.startX = 0
    this.currentX = 0
    this.touchingSidebar = false
    this.thresholdX = 100

    this.transitionEndProperty = null
    this.transitionEndTime = 0

    if (this.hasLeftSidebarTarget) {
      // document.addEventListener("touchstart", this.onTouchStart)
      // document.addEventListener("touchmove", this.onTouchMove)
      // document.addEventListener("touchend", this.onTouchEnd)
      document.addEventListener("turbolinks:click", this.onTurbolinksClick)
    }

    document.querySelectorAll("a").forEach((element) => {
      element.addEventListener("click", (event) => {
        this.hideAllMenus()
      })
    })
  }

  // Left sidebar is marked with `data-turbolinks-permanent` so that it can
  // be animated out when clicking a turbolinks link and it's showing.
  onTurbolinksClick = (event) => {
    this.hideLeftSidebar()
  }

  onTouchStart = (event) => {
    // Only deal with touch events if sidebar is displayed
    if (!this.leftSidebarTarget.classList.contains("sidebar-open")) {
      return
    }

    this.startX = event.touches[0].pageX
    this.currentX = this.startX

    this.touchingSidebar = true
    requestAnimationFrame(this.update)
  }

  onTouchMove = (event) => {
    if (!this.touchingSidebar) {
      return
    }

    this.currentX = event.touches[0].pageX
  }

  onTouchEnd = (event) => {
    if (!this.touchingSidebar) {
      return
    }

    this.touchingSidebar = false

    const translateX = Math.min(0, this.currentX - this.startX)
    this.leftSidebarTarget.style.transform = ""

    if (translateX < 0 && Math.abs(translateX) > this.thresholdX) {
      this.hideLeftSidebar()
    } else {
      this.showLeftSidebar()
    }
  }

  // How far the `x-axis` has moved via touch
  getTranslateX() {
    return Math.min(0, this.currentX - this.startX)
  }

  update = () => {
    if (!this.touchingSidebar) {
      return
    }

    requestAnimationFrame(this.update)

    this.leftSidebarTarget.style.transform = `translateX(${this.getTranslateX()}px)`
  }

  onTransitionEnd = (event) => {
    if (event.propertyName != this.transitionEndProperty && event.elapsedTime != this.transitionEndTime) {
      return
    }

    this.transitionEndProperty = null
    this.transitionEndTime = 0

    this.leftSidebarTarget.classList.remove("sidebar-animatable")
    this.leftSidebarTarget.removeEventListener("transitionend", this.onTransitionEnd)
  }

  showLeftSidebar(event) {
    if (this.hasOverlayTarget) {
      this.overlayTarget.classList.add("is-active")
    }

    if (this.hasLeftSidebarTarget) {
      this.leftSidebarTarget.classList.add("sidebar-animatable")
      this.leftSidebarTarget.classList.add("sidebar-open")

      this.transitionEndProperty = "transform"
      this.transitionEndTime = 0.33

      this.leftSidebarTarget.addEventListener("transitionend", this.onTransitionEnd)
    }
  }

  hideLeftSidebar(event) {
    if (this.hasOverlayTarget) {
      this.overlayTarget.classList.remove("is-active")
    }

    if (this.hasLeftSidebarTarget) {
      this.leftSidebarTarget.classList.add("sidebar-animatable")
      this.leftSidebarTarget.classList.remove("sidebar-open")

      this.transitionEndProperty = "transform"
      this.transitionEndTime = 0.13

      this.leftSidebarTarget.addEventListener("transitionend", this.onTransitionEnd)
    }
  }

  showGlobalSearch(event) {
    if (this.hasGlobalSearchTarget) {
      this.globalSearchTarget.classList.add("global-search-container-open")
      const searchInput = this.globalSearchTarget.querySelector("[data-global-search-target='input']")
      if (searchInput) {
        searchInput.focus()
      }
    }
  }

  hideGlobalSearch(event) {
    if (this.hasGlobalSearchTarget) {
      this.globalSearchTarget.classList.remove("global-search-container-open")
    }
  }

  hideAllMenus() {
    document.querySelectorAll(".menu").forEach((element) => {
      element.classList.remove("menu-open")
    })
  }
}
