import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = []

  connect() {
    // Do not re-run on elements that already have datepickers
    if (this.element.datepicker === undefined) {
      // options = Object.assign({},
      //   this.defaultOptions(),
      //   options
      // )

      const picker = new Pikaday(this.defaultOptions())

      // Store picker on element for reference
      this.element.datepicker = picker

      return picker
    } else {
      console.log("Datepicker already attached")
      return
    }
  }

  // Anytime controller is disconnected to DOM
  disconnect() {
    this.element.datepicker.destroy()
  }

  // Overridden by `options` in constructor
  defaultOptions() {
    return {
      field: this.element,
      format: "MM/DD/YYYY",
      bound: true,
      keyboardInput: false,
      showDaysInNextAndPreviousMonths: true
    }
  }
}
