import Placeholders from "components/placeholders"

export default class HtmlEditor {
  constructor(element, options = {}) {
    let tribute = null

    if (options["placeholderVariables"] != undefined) {
      tribute = new Placeholders(options["placeholderVariables"])
    }
    
    const editor = new FroalaEditor(element,
      Object.assign(
        {},
        {
          attribution: false,
          placeholderText: null,
          enter: FroalaEditor.ENTER_BR,
          heightMin: 100,
          fontFamilyDefaultSelection: "Sans Serif",
          fontSizeSelection: true,
          fontSize: [16, 20, 32],
          fontSizeDefaultSelection: 16,
          imageUploadToS3: this.amazonS3Data(),
          imageMaxSize: 10 * 1024 * 1024, // 10 MB
          // zIndex: 1000,
          scrollableContainer: ".html-editor-container",
          colorsHEXInput: true,
          toolbarBottom: true,
          toolbarSticky: false,
          listAdvancedTypes: false,
          toolbarButtons: [
            ["fontSize", "|"],
            ["bold", "italic", "underline", "textColor", "backgroundColor", "quote", "|"],
            ["align", "formatUL", "formatOLSimple", "|"],
            ["insertLink", "insertImage", "|"],
            ["insertTable", "html", "|"],
            ["clearFormatting"]
          ],
          events: {
            "initialized": function() {
              if (tribute != null) {
                const localEditor = this

                tribute.attach(localEditor.el)

                localEditor.events.on("keydown", function(e) {
                  let $element = $(this.el)
                  if (e.which == FroalaEditor.KEYCODE.ENTER && (tribute.isActive)) {
                    return false
                  }
                }, true)
              }

              if (options["disabled"] == true) {
                editor.edit.off()
              }
            }
          }
        },
        options
      )
    )

    element.froalaEditor = editor

    return editor
  }

  // Required for file storage with Rails and AWS S3.
  // The data hash includes upload directory, etc...
  amazonS3Data() {
    const content = document.querySelector("meta[name='froala-editor-amazon-s3-data']").getAttribute("content")
    const data = atob(content)
    // const data = Buffer.from(data, "base64")
    return JSON.parse(data)
  }
}
