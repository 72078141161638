import { Controller } from "@hotwired/stimulus"
import HtmlEditor from "components/html_editor"

export default class extends Controller {
  static targets = [
    "sendLaterButton",
    "sendLaterCheckbox",
    "sendLaterContainer",
    "sendLaterAtDate",
    "sendLaterAtTime",
    "form", "saveButton",
    "userId",
    "recipients",
    "subject", "body"
  ]

  initialize() {
    this.updateSendLater()
    Masks.init()

    if (this.hasSendLaterAtDateTarget) {
      new Datepicker(this.sendLaterAtDateTarget)
    }

    if (this.hasSendLaterAtTimeTarget) {
      let $sendLaterAtTimeField = $(this.sendLaterAtTimeTarget)

      $sendLaterAtTimeField.selectize({
        placeholder: "Time"
      })
    }

    if (this.hasUserIdTarget) {
      new App.MD.Combobox(this.userIdTarget, {
        placeholder: "From"
      })
    }

    if (this.hasRecipientsTarget) {
      var $recipientsField = $(this.recipientsTarget)
      let selectedJSON = $recipientsField.attr("data-selected")

      let records = JSON.parse(selectedJSON)

      new App.MD.Combobox(this.recipientsTarget, {
        placeholder: "Search contacts and lists",
        minimumInputLength: 1,
        maximumSelectionLength: Infinity,
        ajax: {
          delay: 250,
          url: this.recipientsTarget.getAttribute("data-search-url"),
          dataType: "json"
        },
        data: records,
        templateSelection: this.formatSelection,
        templateResult: this.formatResult
      })
    }

    if (this.hasSubjectTarget) {
      new HtmlEditor(this.subjectTarget, {
        placeholderText: "Subject",
        heightMin: 30,
        toolbarInline: true,
        toolbarButtons: [],
        placeholderVariables: "message"
      })
    }

    if (this.hasBodyTarget) {
      new HtmlEditor(this.bodyTarget, {
        heightMin: 300,
        placeholderText: "Body",
        placeholderVariables: "message"
      })
    }
  }

  updateSendLater(event) {
    if (this.hasSendLaterCheckboxTarget) {
      if (this.sendLaterCheckboxTarget.checked) {
        this.sendLaterContainerTarget.style.display = "block"
        this.sendLaterButtonTarget.classList.add("color-blue")
      } else {
        this.sendLaterContainerTarget.style.display = "none"
        this.sendLaterButtonTarget.classList.remove("color-blue")
      }
    }
  }

  toggleSendLater(event) {
    if (this.sendLaterCheckboxTarget.checked) {
      this.sendLaterCheckboxTarget.checked = false
    } else {
      this.sendLaterCheckboxTarget.checked = true
      // this.sendLaterAtDateTarget.value = ""
      // this.sendLaterAtTimeTarget.value = ""
      // this.sendLaterContainerTarget.style.display = "none"
      // this.sendLaterButtonTarget.classList.remove("color-blue")
    }

    this.updateSendLater()
  }

  // Selected items
  formatSelection(record) {
    if (record.recipient_type == "client") {
      return $(`
        <div class="flex flex-align-left flex-0">
          <em class="material-icons color-green font-size-large">contacts</em>&nbsp;
          <div class="">
            ${record.text}
          </div>
        </div>
      `)
    }

    if (record.recipient_type == "partner") {
      return $(`
        <div class="flex flex-align-left flex-0">
          <em class="material-icons color-blue font-size-large">contacts</em>&nbsp;
          <div class="">
            ${record.text}
          </div>
        </div>
      `)
    }

    if (record.recipient_type == "client_list") {
      return $(`
        <div class="flex flex-align-left flex-0">
          <em class="material-icons color-green font-size-large">list</em>&nbsp;
          <div class="">
            ${record.text}
          </div>
        </div>
      `)
    }

    if (record.recipient_type == "partner_list") {
      return $(`
        <div class="flex flex-align-left flex-0">
          <em class="material-icons color-blue font-size-large">list</em>&nbsp;
          <div class="">
            ${record.text}
          </div>
        </div>
      `)
    }
  }

  // Autocomplete list item
  formatResult(record) {
    if (record.loading) {
      return $(`
        <div class="list-item-primary color-helper">
          ${record.text}
        </div>
      `)
    }

    if (record.recipient_type == "client") {
      return $(`
        <div class="list-item-icon">
          <em class="material-icons color-green font-size-large">contacts</em>
        </div>
        <div class="list-item-primary">
          ${record.text}
          <div class="list-item-primary-subtext">
            Client
            <span class="dot-divider">&nbsp;&bull;&nbsp;</span>
            ${record.subtext}
          </div>
        </div>
      `)
    }

    if (record.recipient_type == "partner") {
      return $(`
        <div class="list-item-icon">
          <em class="material-icons color-blue font-size-large">contacts</em>
        </div>
        <div class="list-item-primary">
          ${record.text}
          <div class="list-item-primary-subtext">
            Partner
            <span class="dot-divider">&nbsp;&bull;&nbsp;</span>
            ${record.subtext}
          </div>
        </div>
      `)
    }

    if (record.recipient_type == "client_list") {
      return $(`
        <div class="list-item-icon">
          <em class="material-icons color-green font-size-large">list</em>
        </div>
        <div class="list-item-primary">
          ${record.text}
          <div class="list-item-primary-subtext">
            ${record.subtext}
          </div>
        </div>
      `)
    }

    if (record.recipient_type == "partner_list") {
      return $(`
        <div class="list-item-icon">
          <em class="material-icons color-blue font-size-large">list</em>
        </div>
        <div class="list-item-primary">
          ${record.text}
          <div class="list-item-primary-subtext">
            ${record.subtext}
          </div>
        </div>
      `)
    }
  }
}
