import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "partnerTypeId", "partnerId" ]

  initialize() {
    new  App.MD.Combobox(this.partnerTypeIdTarget)
    if (this.hasPartnerIdTarget) {
      new  App.MD.Combobox(this.partnerIdTarget)
    }
  }
}
