import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "teamMemberId", "useDynamicDueDateCheckbox", "dynamicDueAtContainer" ]

  initialize() {
    var controller = this
    this.toggleDynamicDueDate()

    var $teamMemberField = $(this.teamMemberIdTarget)

    Masks.init()
    new  App.MD.Combobox($teamMemberField, { placeholder: "Unassigned" })
  }

  toggleDynamicDueDate() {
    let checkbox = this.useDynamicDueDateCheckboxTarget
    let isChecked = checkbox.checked

    if (isChecked) {
      this.showDynamicDueDate()
    } else {
      this.showStaticDueDate()
    }
  }

  showDynamicDueDate() {
    this.useDynamicDueDateCheckboxTarget.checked = true
    this.dynamicDueAtContainerTarget.style.display = "block"
    // this.dueAtContainerTarget.style.display = "none"
  }

  showStaticDueDate() {
    this.useDynamicDueDateCheckboxTarget.checked = false
    this.dynamicDueAtContainerTarget.style.display = "none"
    // this.dueAtContainerTarget.style.display = "block"
  }
}
