import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "form",
    "dynamicFields",
    "clientSearchField",
    "staticButton", "dynamicButton",
    "stage", "loanProduct", "closingWithin"
  ]

  initialize() {
    if (this.hasStaticButtonTarget) {
      this.toggleType()
    }

    if (this.hasStageTarget) {
      new App.MD.Combobox(this.stageTarget, { maximumSelectionLength: Infinity, closeOnSelect: true, placeholder: "Any" })
    }

    if (this.hasLoanProductTarget) {
      new App.MD.Combobox(this.loanProductTarget, { maximumSelectionLength: Infinity, closeOnSelect: true, placeholder: "Any" })
    }

    if (this.hasClosingWithinTarget) {
      $(this.closingWithinTarget).autoNumeric("init", {
        vMin: "-999999999",
        vMax: "999999999",
        aPad: false
      })
    }
  }

  toggleType() {
    if (this.staticButtonTarget.checked) {
      this.setStatic()
    } else {
      this.setDynamic()
    }
  }

  setStatic() {
    this.dynamicFieldsTarget.style.display = "none"
  }

  setDynamic() {
    this.dynamicFieldsTarget.style.display = "block"
  }
}
