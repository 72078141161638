import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "show", "hide", "content", "requiredField" ]

  initialize() {
    if (this.hasShowTarget) {
      if (this.showTarget.checked) {
        this.show()
      } else {
        this.hide()
      }
    }
  }

  show(_event) {
    // if (this.hasContentTarget) {
    //   this.contentTarget.style.display = ""
    //   this.requiredFieldTargets.forEach((field) => {
    //     field.required = true
    //   })
    // }
    this.contentTargets.forEach((element) => {
      element.style.display = ""
      this.requiredFieldTargets.forEach((field) => {
        field.required = true
      })
    })
  }

  hide(_event) {
    // if (this.hasContentTarget) {
    //   this.contentTarget.style.display = "none"
    //   this.requiredFieldTargets.forEach((field) => {
    //     field.required = false
    //   })
    // }
    this.contentTargets.forEach((element) => {
      element.style.display = "none"
      this.requiredFieldTargets.forEach((field) => {
        field.required = false
      })
    })
  }
}
