import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "contingencies", "disclosures", "approvedLoanAmount", "expirationDate" ]

  initialize() {
    new HtmlEditor(this.contingenciesTarget, { heightMin: 100, placeholderText: "Leave blank if no contingencies" })
    new HtmlEditor(this.disclosuresTarget, { heightMin: 100, placeholderText: "Default disclosures" })

    $(this.approvedLoanAmountTarget).autoNumeric("init", {
      aSign: "$ ",
      aSep: ",",
      aDec: ".",
      vMin: "0",
      dGroup: "3",
      aPad: true
    }).attr("placeholder", "$")

    new Datepicker(this.expirationDateTarget, { position: "bottom right" })

    Masks.init()
  }
}
