import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "tab", "tabContent" ]

  initialize() {
    this.update()
  }

  select(event) {
    this.data.set("selected-tab", event.currentTarget.getAttribute("data-tab-id"))
    this.update()
  }

  update() {
    let selectedTabId = this.data.get("selected-tab")

    $(this.tabTargets).removeClass("tab-selected")
    $(this.tabContentTargets).hide()

    this.tabTargets.forEach((element, index) => {
      element.classList.toggle("tab-selected", element.getAttribute("data-tab-id") == selectedTabId)
    })

    this.tabContentTargets.forEach((element, index) => {
      if (element.getAttribute("data-tab-id") == selectedTabId) {
        $(element).show()
      } else {
        $(element).hide()
      }
    })
  }
}
