import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "emailAddressType", "emailAddresses", "addEmailAddressButton",
    "phoneNumberType", "phoneNumbers", "addPhoneNumberButton",
    "organizationMembers", "addOrganizationMemberButton",
    "organizationMemberOrganizationId", "organizationMemberPosition",
    "dateOfBirth"
  ]

  initialize() {
    Masks.init()

    this.autocompleteEmailAddressTypes()
    this.autocompletePhoneNumberTypes()
    this.autocompleteOrganizations()
  }

  autocompleteEmailAddressTypes() {
    this.emailAddressTypeTargets.forEach(function(target) {
      $(target).autocompleteSuggest({
        source: [target.getAttribute("data-autocomplete-list").split(",")],
        openOnFocus: true
      })
    })
  }

  autocompletePhoneNumberTypes() {
    this.phoneNumberTypeTargets.forEach(function(target) {
      $(target).autocompleteSuggest({
        source: [target.getAttribute("data-autocomplete-list").split(",")],
        openOnFocus: true
      })
    })
  }

  autocompleteOrganizations() {
    var controller = this

    this.organizationMemberOrganizationIdTargets.forEach(function(target) {
      let selectedJSON = target.getAttribute("data-selected")
      let records = JSON.parse(selectedJSON)

      new  App.MD.Combobox(target, {
        placeholder: "Search organizations by name or license number",
        minimumInputLength: 1,
        ajax: {
          delay: 250,
          url: target.getAttribute("data-search-url"),
          dataType: "json"
        },
        data: records,
        templateResult: controller.formatResult
      })
    })
  }

  addEmailAddress() {
    var button = this.addEmailAddressButtonTarget
    var time = new Date().getTime()
    var regexp = new RegExp(button.dataset.id, "g")
    var element = button.dataset.fields

    $(this.emailAddressesTarget).append(element.replace(regexp, time))

    this.autocompleteEmailAddressTypes()
    this.autocompletePhoneNumberTypes()
  }

  addPhoneNumber() {
    var button = this.addPhoneNumberButtonTarget
    var time = new Date().getTime()
    var regexp = new RegExp(button.dataset.id, "g")
    var element = button.dataset.fields

    $(this.phoneNumbersTarget).append(element.replace(regexp, time))
    Masks.init()

    this.autocompleteEmailAddressTypes()
    this.autocompletePhoneNumberTypes()
  }

  addOrganizationMember() {
    var button = this.addOrganizationMemberButtonTarget
    var time = new Date().getTime()
    var regexp = new RegExp(button.dataset.id, "g")
    var element = button.dataset.fields

    $(this.organizationMembersTarget).append(element.replace(regexp, time))

    this.autocompleteOrganizations()
  }

  removeAssociationRecord(event) {
    var $removeButton = $(event.target)
    var $record = $removeButton.closest(".association-record")
    $record.find("input[type='hidden'].association-record-destroy").val("1")
    $record.hide()
  }

  formatSelection(record) {
    return $(`
      <div class="flex flex-align-left flex-0">
        <div class="">
          ${record.text} (${record.license_number})
        </div>
      </div>
    `)
  }

  formatResult(record) {
    console.log(record)
    if (record.loading) {
      return $(`
        <div class="list-item-primary color-helper">
          ${record.text}
        </div>
      `)
    }

    return $(`
      <div class="list-item-primary">
        ${record.name}
        <div class="list-item-primary-subtext">
          ${record.address}
        </div>
        <div class="list-item-primary-subtext">
          <strong>License:</strong>&nbsp;
          ${record.license_number}
        </div>
      </div>
    `)
  }
}
