import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "stageCategory", "stage", "loanProduct", "closingWithin", "approvalIssuedWithin", "closingDateFrom", "closingDateTo" ]

  initialize() {
    // For authenticated
    if (document.getElementById("filter_stage")) {
      new  App.MD.Combobox(this.stageTarget, { maximumSelectionLength: Infinity, closeOnSelect: true })
    }

    if (document.getElementById("filter_loan_product")) {
      new  App.MD.Combobox(this.loanProductTarget, { maximumSelectionLength: Infinity, closeOnSelect: true })
    }

    $(this.closingWithinTarget).autoNumeric("init", {
      vMin: "-999999999",
      vMax: "999999999",
      aPad: false
    })

    $(this.approvalIssuedWithinTarget).autoNumeric("init", {
      vMin: "-999999999",
      vMax: "999999999",
      aPad: false
    })

    new Datepicker(this.closingDateFromTarget)
    new Datepicker(this.closingDateToTarget)
  }
}
