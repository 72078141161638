import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "emailNotificationSelect" ]

  bulkAssign(event) {
    let bulkAssignId = event.target.value

    this.emailNotificationSelectTargets.forEach((element) => {
      element.value = bulkAssignId
    })

    event.target.selectedIndex = 0
  }
}
