import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  initialize() {
    $("#address_address_type").autoComplete({
      source: function(term, response) {
        term = term.toLowerCase()
        let matches = []
        let records = $("#address_address_type").attr("data-autocomplete-list").split(",")

        for (let i = 0; i < records.length; i++) {
          let record = records[i]

          if (fuzzysearch(term, record.toLowerCase())) {
            matches.push(record)
          }
        }

        response(matches)
      },
      minChars: 0,
      menuClass: "list",
      renderItem: function(item, search) {
        search = search.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')
        let regex = new RegExp(`(${search.split(" ").join("|")})`, "gi")

        return `
          <div class="autocomplete-suggestion list-item list-one-line" data-value="${item}">
            <div class="list-item-primary">
              ${item.replace(regex, "<b>$1</b>")}
            </div>
          </div>
        `
      },
      onSelect: function(event, term, item) {
        event.preventDefault()
      }
    })
  }
}
