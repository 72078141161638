import { Controller } from "@hotwired/stimulus"
import consumer from "channels/consumer"

export default class extends Controller {
  static targets = [ "list" ]

  connect() {
    this.subscription = consumer.subscriptions.create({
      channel: "TransactionRecordConversationsChannel",
      transaction_record_uuid: this.element.getAttribute("data-transaction-record-uuid")
    }, {
      connected: this._connected.bind(this),
      disconnected: this._disconnected.bind(this),
      received: this._received.bind(this)
    })
  }

  disconnect() {
    if (this.subscription) {
      consumer.subscriptions.remove(this.subscription)
    }
  }

  _connected() {}

  _disconnected() {}

  _received(data) {
    if (data.conversation) {
      // Remove conversation list_item from notifications dialog and TR#conversations tab
      document.querySelectorAll(`[data-attribute='conversation_${data.conversation_uuid}.list_item_component']`).forEach((element) => {
        element.remove()
      })

      // Only add updated conversation to TR#conversations tab
      let conversationElement = document.createRange().createContextualFragment(data.conversation)
      this.listTarget.prepend(conversationElement)
    }

    // if (data.resolve_link) {
    //   $(`[data-attribute='conversation_${data.conversation_uuid}.menu_items.resolve_link_component']`).replaceWith(data.resolve_link)
    // }

    // if (data.resolved) {
    //   $(`[data-attribute='conversation_${data.conversation_uuid}.list_item_component']`).replaceWith(
    //     data.resolved
    //   )
    // }

    // if (data.unresolved) {
    //   $(`[data-attribute='conversation_${data.conversation_uuid}.list_item_component']`).replaceWith(
    //     data.unresolved
    //   )
    // }
  }
}
