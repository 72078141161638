import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "staticCheckbox",
    "dynamicCheckbox"
  ]

  connect() {
    if (this.staticCheckboxTarget.checked) {
      this.setStatic()
    } else if (this.dynamicCheckboxTarget.checked) {
      this.setDynamic()
    } else {
      this.setStatic()
    }
  }

  setStatic(event) {
    if (this.dynamicCheckboxTarget.checked) {
      this.dynamicCheckboxTarget.checked = false
      const changeEvent = new Event("change")
      this.dynamicCheckboxTarget.dispatchEvent(changeEvent)
    }
  }

  setDynamic(event) {
    if (this.staticCheckboxTarget.checked) {
      this.staticCheckboxTarget.checked = false
      const changeEvent = new Event("change")
      this.staticCheckboxTarget.dispatchEvent(changeEvent)
    }
  }
}
