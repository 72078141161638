/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Rails UJS
import Rails from "@rails/ujs"
Rails.start()

// Turbolinks
import Turbolinks from "turbolinks"
Turbolinks.start()

import Mousetrap from "mousetrap"

Mousetrap.bind("esc", () => {
  if (document.querySelector(".dialog-container")) {
    history.back()
  }
})

window.addEventListener("popstate", (event) => {
  if (event.state.dialog) {
    const dialogId = event.state.dialog.id

    if (dialogId == "bottomsheet") {
      Global.stimulusController(document.body, "layout").hideBottomsheet()
    } else if (dialogId == "global-search") {
      const element = document.querySelector("[data-controller='global-search']")

      Global.stimulusController(element, "global-search").hide()
    } else {
      const dialogElement = document.getElementById(dialogId)

      if (dialogElement) {
        Global.stimulusController(dialogElement, "dialog").remove()
      }
    }
  }
})

import LocalTime from "local-time"
LocalTime.start()

// `jquery` is added to global namespace in `config/webpack/environment.js`
// If using `@rails/ujs`, you do not need to use `jquery-ujs`.
// import $ from "jquery"

// ActionCable
require("channels")

// StimulusJS
require("controllers/index")

// Assign globals
//----------------------------------------------------------------------

// Add to global space so jquery plugins can access.
// NOTE: This goes against JS module convention, but is very useful;
// otherwise cannot use jQuery in server-generated JS.
import $ from "jquery"
window.$ = $
window.jQuery = $

jQuery.extend(jQuery.fn, {
  increment(param) {
    return this.each(function() {
      const $element = $(this)

      if (param) {
        $element.text(param)
      } else {
        const $value = parseInt($element.text())
        if ($value !== NaN) {
          $element.text($value + 1)
        }
      }
      return $element
    })
  },

  decrement(param) {
    return this.each(function() {
      const $element = $(this)

      if (param) {
        $element.text(param)
      } else {
        const $value = parseInt($element.text())
        if ($value !== NaN) {
          if ($value <= 0) {
            $element.text(0)
          } else {
            $element.text($value - 1)
          }
        }
      }
      return $element
    })
  },

  isEmptyList() {
    return this.filter( function() {
      return $(this).children().length > 0;
    }).length === 0;
  }
})

// Allow global access to this helper/utility class
import Global from "classes/global"
window.Global = Global

import BinaryInsert from "classes/binary_insert"
window.BinaryInsert = BinaryInsert

import Snackbar from "classes/snackbar"
window.Snackbar = Snackbar

// Events
//----------------------------------------------------------------------

document.addEventListener("turbolinks:load", () => {
  // Persist Rails' `flash` notifications between requests
  if (document.body.hasAttribute("data-snackbar") && document.body.getAttribute("data-snackbar").length > 0) {
    Snackbar.create(document.body.getAttribute("data-snackbar"))
    document.body.removeAttribute("data-snackbar")
  }

  if (document.body.hasAttribute("data-snackbar-error") && document.body.getAttribute("data-snackbar-error").length > 0) {
    Snackbar.create(document.body.getAttribute("data-snackbar-error"), { cssClass: "snackbar-error" })
    document.body.removeAttribute("data-snackbar-error")
  }
})
