import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "tab",
    "panel"
  ]

  static values = {
    selectedIndex: Number
  }

  connect() {}

  select(event) {
    this.selectedIndexValue = this.tabTargets.indexOf(event.currentTarget)
  }

  selectedIndexValueChanged() {
    this.panelTargets.forEach((element) => {
      element.hidden = true
    })

    this.panelTargets[this.selectedIndexValue].hidden = false
  }

  disconnect() {}
}
