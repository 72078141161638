import { Controller } from "@hotwired/stimulus"
import HtmlEditor from "components/html_editor"

export default class extends Controller {
  static targets = [
    "sendLaterButton",
    "sendLaterCheckbox",
    "sendLaterContainer",
    "sendLaterAtDate",
    "sendLaterAtTime",
    "from",
    "subject",
    "body"
  ]

  initialize() {
    new  App.MD.Combobox(this.fromTarget)
    new HtmlEditor(this.subjectTarget, {
      placeholderText: "Subject",
      heightMin: 30,
      toolbarInline: true,
      toolbarButtons: [],
      placeholderVariables: "lead"
    })
    new HtmlEditor(this.bodyTarget, {
      heightMin: 200,
      placeholderText: "Body",
      placeholderVariables: "lead"
    })

    this.updateSendLater()

    if (this.hasSendLaterAtDateTarget) {
      new Datepicker(this.sendLaterAtDateTarget)
    }

    if (this.hasSendLaterAtTimeTarget) {
      let $sendLaterAtTimeField = $(this.sendLaterAtTimeTarget)

      $sendLaterAtTimeField.selectize({
        placeholder: "Time"
      })
    }
  }

  updateSendLater(event) {
    if (this.hasSendLaterCheckboxTarget) {
      if (this.sendLaterCheckboxTarget.checked) {
        this.sendLaterContainerTarget.style.display = "block"
        this.sendLaterButtonTarget.classList.add("color-blue")
      } else {
        this.sendLaterContainerTarget.style.display = "none"
        this.sendLaterButtonTarget.classList.remove("color-blue")
      }
    }
  }

  toggleSendLater(event) {
    if (this.sendLaterCheckboxTarget.checked) {
      this.sendLaterCheckboxTarget.checked = false
    } else {
      this.sendLaterCheckboxTarget.checked = true
    }

    this.updateSendLater()
  }
}
