import { Controller } from "@hotwired/stimulus"
import HtmlEditor from "components/html_editor"

export default class extends Controller {
  static values = {
    placeholderVariables: String,
    disabled: Boolean
  }

  connect() {
    let options = {
      placeholderVariables: this.placeholderVariablesValue,
      heightMin: 40,
      toolbarInline: true,
      toolbarButtons: []
    }

    // To set initial disable/enable
    if (this.disabledValue) {
      options = Object.assign(options, { disabled: true })
    }

    const editor = new HtmlEditor(this.element, options)

    this.element.editor = editor
  }

  disconnect() {
    this.element.editor.destroy()
  }

  disabledValueChanged(newValue) {
    if (this.disabledValue == true) {
      this.disableEditor()
    } else {
      this.enableEditor()
    }
  }

  disableEditor() {
    if (this.element.editor) {
      this.element.editor.edit.off()
    }
  }

  enableEditor() {
    if (this.element.editor) {
      this.element.editor.edit.on()
    }
  }
}