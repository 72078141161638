import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "addressField",
    "addressLine1",
    "addressLine2",
    "city",
    "state",
    "zip"
  ]

  initialize() {
    const controller = this
    const apiKey = document.querySelector("meta[name='address-autocomplete-api-key']").content

    $(controller.addressLine1Target).autoComplete({
      source: function(text, responseCallback) {
        text = text.toLowerCase()

        $.ajax({
          type: "POST",
          dataType: "json",
          url: "https://api.addressy.com/Capture/Interactive/Find/v1.1/json3.ws",
          data: {
            Key: apiKey,
            Text: text,
            IsMiddleware: false,
            Countries: "US",
            Language: "en-us"
          },
          success: function(data) {
            let matches = data.Items.filter(function(record) {
              return record.Type === "Address"
            })

            responseCallback(matches)
          }
        })
      },
      minChars: 1,
      limit: 3,
      delay: 150,
      cache: false,
      menuClass: "list",
      renderItem: function(item, search) {
        search = search.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&")
        let regex = new RegExp(`(${search.split(" ").join("|")})`, "gi")

        return `
          <div class="autocomplete-suggestion list-item list-two-lines" data-id="${item.Id}" data-text="${item.Text}" data-description="${item.Description}" data-value="${item.Text}">
            <div class="list-item-primary">
              ${item.Text.replace(regex, "<b>$1</b>")},
              ${item.Description.replace(regex, "<b>$1</b>")}
            </div>
          </div>
        `
      },
      onSelect: function(event, term, item) {
        controller.addressLine1Target.value = item.data("text")

        let cityStateZip = controller.splitIntoAddressParts(item.data("description"))

        controller.cityTarget.value = cityStateZip.city
        controller.stateTarget.value = cityStateZip.state
        controller.zipTarget.value = cityStateZip.zip

        event.preventDefault()
        event.stopPropagation()
      }
    })
  }

  // The data is returned from the API in format
  // "city state zip" ("Los Angelos CA 12345").
  splitIntoAddressParts(combinedCityStateZip) {
    combinedCityStateZip = combinedCityStateZip.split(" ")
    let zip = combinedCityStateZip.pop()
    let state = combinedCityStateZip.pop()
    let city = combinedCityStateZip.join(" ")

    return {
      city: city,
      state: state,
      zip: zip
    }
  }
}
