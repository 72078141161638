import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "rentOption", "ownOption", "rentFreeOption",
    "rentField", "ownField"
  ]

  // Once, when controller first instantiated
  initialize() {
    if (this.rentOptionTarget.checked) {
      this.showRent()
    } else if (this.ownOptionTarget.checked) {
      this.showOwn()
    } else {
      this.showRentFree()
    }
  }

  // Show rent, rent-or-own
  showRent() {
    this.ownFieldTargets.forEach((field) => {
      field.style.display = "none"
    })

    this.rentFieldTargets.forEach((field) => {
      field.style.display = ""
    })
  }

  // Show own, rent-or-own
  showOwn() {
    this.rentFieldTargets.forEach((field) => {
      field.style.display = "none"
    })

    this.ownFieldTargets.forEach((field) => {
      field.style.display = ""
    })
  }

  // Hide all
  showRentFree() {
    this.ownFieldTargets.forEach((field) => {
      field.style.display = "none"
    })

    this.rentFieldTargets.forEach((field) => {
      field.style.display = "none"
    })
  }
}
