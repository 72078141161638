import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"
import Rails from "@rails/ujs"

export default class extends Controller {
  static values = {
    listName: String,
    url: String
  }

  connect() {
    this.sortable = Sortable.create(this.element, {
      group: this.listNameValue,
      handle: ".draggable-handle",
      dragClass: "sortable-drag",
      ghostClass: "sortable-ghost",
      removeCloneOnHide: true,
      onSort: this.onSort.bind(this)
    })
  }

  onSort(event) {
    const items = this.element.children

    // Rails expects `params[:ids]` in format `?ids[]=1&ids[]=2&...`
    // and Rails-UJS does not have a built-in way to do this.
    const idsAsParams = Array.from(items).map((element, index) => {
      return `ids[]=${element.getAttribute("data-id")}`
    }).join("&")

    Rails.ajax({
      type: "PUT",
      url: this.urlValue,
      data: idsAsParams
    })
  }

  disconnect() {

  }
}
