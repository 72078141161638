import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "partnerType", "stage", "loanProduct", "closingWithin" ]

  initialize() {
    new  App.MD.Combobox(this.partnerTypeTarget, { maximumSelectionLength: Infinity, closeOnSelect: true })

    new  App.MD.Combobox(this.stageTarget, { maximumSelectionLength: Infinity, closeOnSelect: true })

    new  App.MD.Combobox(this.loanProductTarget, { maximumSelectionLength: Infinity, closeOnSelect: true })

    $(this.closingWithinTarget).autoNumeric("init", {
      vMin: "-999999999",
      vMax: "999999999",
      aPad: false
    })
  }
}
