import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "transactionRecordLink" ]

  initialize() {

  }

  // Assignment list-item is an AJAX request that keeps bubbling
  // click event up to it instead of activating on this link.
  clickTransactionRecordLink(event) {
    event.stopPropagation()
    event.preventDefault()
    Turbolinks.visit(event.target.closest("a").href) // Target may be a nested element
  }
}
