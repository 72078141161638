import { Controller } from "@hotwired/stimulus"
import { DateTime, Info } from "luxon"

export default class extends Controller {
  static targets = [
    "background",
    "hidden",
    "input",
    "displayText",
    "currentMonth",
    "monthDisplay",
    "yearDisplay",
    "days",
    "day"
  ]

  static values = {
    dateFormat: String
  }

  initialize() {
    document.body.addEventListener("click", (event) => {
      if (event && event.target.tagName.toUpperCase() == "INPUT") { return }
      if (event.target.closest(".datepicker-container")) { return }

      document.querySelectorAll(".datepicker-background").forEach((element) => {
        element.classList.remove("datepicker-background-visible")
      })
    })
  }

  connect() {
    window.DateTime = DateTime
    window.Info = Info

    if (this.hasBackgroundTarget) {
      this.backgroundTarget.addEventListener("click", (event) => {
        if (event.target == this.backgroundTarget) {
          this.hide()
        }
      })
    }

    // Storing actual DateTime object so operations can be performed against
    // it without having to constantly parse into objects.
    this.todayDate = DateTime.local()

    // this.setDefaultInputValue()
    // this.setInputValue(this.hiddenTarget.value)
    this.setSelectedDateFromInputValue()

    if (this.hasDisplayTextTarget) {
      if (this.hiddenTarget.value == "" || this.hiddenTarget.value == null) {
        this.displayTextTarget.innerHTML = "No date"
      } else {
        this.displayTextTarget.innerHTML = this.formattedDate(this.hiddenTarget.value)
      }
    }

    // this.inputTarget.value = this.formattedDate(this.hiddenTarget.value)

    this.render()
  }

  setDefaultInputValue() {
    if (this.hiddenTarget.value == "") {
      this.setInputValue(DateTime.local().toISODate())
    } else {
      this.setInputValue(this.hiddenTarget.value)
      // this.hiddenTarget.value = this.getDateFromInput().toFormat(this.dateFormatValue)
    }
  }

  formattedDate(value) {
    return DateTime.fromSQL(value).toFormat(this.dateFormatValue)
  }

  setInputValue(value) {
    this.hiddenTarget.value = value

    if (this.hasDisplayTextTarget) {
      this.displayTextTarget.innerHTML = this.formattedDate(value)
    }

    // this.inputTarget.value = DateTime.fromSQL(value).toFormat(this.dateFormatValue)
  }

  setInputWithFormattedDate(event) {
    // this.inputTarget.value = this.formattedDate(this.hiddenTarget.value)
  }

  // Assumes input has a value
  setSelectedDateFromInputValue() {
    this.selectedDate = this.getDateFromInput()
    this.day = this.selectedDate.day
    this.month = this.selectedDate.month
    this.year = this.selectedDate.year
  }

  select(event) {
    event.preventDefault()
    event.stopPropagation()

    this.dayTargets.forEach((element) => {
      element.classList.remove("datepicker-day-selected")
    })

    event.target.classList.add("datepicker-day-selected")

    this.setInputValue(event.target.getAttribute("data-value"))
    this.setSelectedDateFromInputValue()
    this.resetInput()
    this.render()
    this.hide()
  }

  resetInput() {
    this.inputTarget.value = null
    this.inputTarget.focus()
  }

  clear(event) {
    this.hiddenTarget.value = null
    this.resetInput()

    if (this.hasDisplayTextTarget) {
      this.displayTextTarget.innerHTML = "No date"
    }

    this.dayTargets.forEach((element) => {
      element.classList.remove("datepicker-day-selected")
    })
  }

  // Parse value from text field into date object or use today as value
  getDateFromInput() {
    if (DateTime.fromSQL(this.hiddenTarget.value).invalid) {
      return this.todayDate || DateTime.local()
    } else {
      return DateTime.fromSQL(this.hiddenTarget.value)
    }
  }

  previousMonth() {
    this.month--

    // Going from January (1) to December (12)
    if (this.month < 1) {
      this.month = 12
      this.year--
    }

    this.render()
  }

  nextMonth() {
    this.month++

    // Going from December (12) to January (1)
    if (this.month > 12) {
      this.month = 1
      this.year++
    }

    this.render()
  }

  today() {
    this.dayTargets.forEach((element) => {
      if (element.getAttribute("data-value") == this.todayDate.toISODate()) {
        element.classList.add("datepicker-day-selected")
      } else {
        element.classList.remove("datepicker-day-selected")
      }
    })

    this.setInputValue(this.todayDate.toISODate())
    this.setSelectedDateFromInputValue()

    this.render()
    this.hide()
  }

  show(event) {
    document.querySelectorAll(".dialog-toolbar").forEach((element) => {
      element.style.zIndex = 24
    })

    if (this.hasBackgroundTarget) {
      this.backgroundTarget.classList.add("datepicker-background-visible")
    }

    this.inputTarget.value = ""
  }

  hide(event) {
    document.querySelectorAll(".dialog-toolbar").forEach((element) => {
      element.style.zIndex = 25
    })

    if (this.hasBackgroundTarget) {
      this.backgroundTarget.classList.remove("datepicker-background-visible")
    }
  }

  setManually(event) {
    if (!this.hasInputTarget) { return }

    const value = this.inputTarget.value.trim().replace(",", "")
    let parsedDate = null

    if (value == "tod" || value == "toda" || value == "today") {
      this.setInputValue(this.todayDate.toISODate())
      this.setSelectedDateFromInputValue()
      this.render()
      return
    }

    // "oct 1" format
    parsedDate = DateTime.fromFormat(value, "LLL d")
    if (parsedDate.invalid == null) {
      this.setInputValue(parsedDate.toISODate())
      this.setSelectedDateFromInputValue()
      this.render()
      return
    }

    // "oct 1 18" format
    parsedDate = DateTime.fromFormat(value, "LLL d yy")
    if (parsedDate.invalid == null) {
      this.setInputValue(parsedDate.toISODate())
      this.setSelectedDateFromInputValue()
      this.render()
      return
    }

    // "oct 1 2018" format
    parsedDate = DateTime.fromFormat(value, "LLL d yyyy")
    if (parsedDate.invalid == null) {
      this.setInputValue(parsedDate.toISODate())
      this.setSelectedDateFromInputValue()
      this.render()
      return
    }

    // "october 1" format
    parsedDate = DateTime.fromFormat(value, "LLLL d")
    if (parsedDate.invalid == null) {
      this.setInputValue(parsedDate.toISODate())
      this.setSelectedDateFromInputValue()
      this.render()
      return
    }

    // "october 1 18" format
    parsedDate = DateTime.fromFormat(value, "LLL d yy")
    if (parsedDate.invalid == null) {
      this.setInputValue(parsedDate.toISODate())
      this.setSelectedDateFromInputValue()
      this.render()
      return
    }

    // "october 1 2018" format
    parsedDate = DateTime.fromFormat(value, "LLLL d yyyy")
    if (parsedDate.invalid == null) {
      this.setInputValue(parsedDate.toISODate())
      this.setSelectedDateFromInputValue()
      this.render()
      return
    }

    // "8/31" format
    parsedDate = DateTime.fromFormat(value, "L/d")
    if (parsedDate.invalid == null) {
      this.setInputValue(parsedDate.toISODate())
      this.setSelectedDateFromInputValue()
      this.render()
      return
    }

    // "8/31/22" format
    parsedDate = DateTime.fromFormat(value, "L/d/yy")
    if (parsedDate.invalid == null) {
      this.setInputValue(parsedDate.toISODate())
      this.setSelectedDateFromInputValue()
      this.render()
      return
    }

    // "8/31/1987" format
    parsedDate = DateTime.fromFormat(value, "L/d/yyyy")
    if (parsedDate.invalid == null) {
      this.setInputValue(parsedDate.toISODate())
      this.setSelectedDateFromInputValue()
      this.render()
      return
    }

    // "2015-12-31" format
    parsedDate = DateTime.fromFormat(value, "yyyy-L-d")
    if (parsedDate.invalid == null) {
      this.setInputValue(parsedDate.toISODate())
      this.setSelectedDateFromInputValue()
      this.render()
      return
    }

    // this.setDefaultInputValue()
    // this.setSelectedDateFromInputValue()
    // this.render()
  }

  // Updates calendar days
  render() {
    // Update "MONTH_NAME YEAR" header
    this.monthDisplayTarget.innerHTML = `${Info.monthsFormat("long")[this.month - 1]}`
    this.yearDisplayTarget.innerHTML = `${this.year}`

    // Rebuild days
    this.daysTarget.innerHTML = ""

    // Which day of the week (Mon, Tue, etc) does the 1st day of this month start on (base 0 indexed)
    let startingWeekdayForMonth = DateTime.local(this.year, this.month, 1).startOf("month").weekday

    // 42 days ALWAYS to avoid bouncing between 4-6 rows
    for (let i = 0; i < 42; i++) {
      let day = DateTime.local(this.year, this.month, 1).plus({ days: (i - startingWeekdayForMonth) })

      let dayElement = document.createElement("button")
      dayElement.setAttribute("data-value", day.toISODate())
      dayElement.setAttribute("data-action", "datepicker#select")
      dayElement.setAttribute("data-datepicker-target", "day")
      dayElement.innerHTML = day.day
      dayElement.classList.add("datepicker-day")

      if (day.month < this.month) {
        dayElement.classList.add("datepicker-day-previous-month")
      }

      if (day.month > this.month) {
        dayElement.classList.add("datepicker-day-next-month")
      }

      if (day.toISODate() == this.todayDate.toISODate()) {
        dayElement.classList.add("datepicker-day-today")
      }

      if (day.toISODate() == this.selectedDate.toISODate()) {
        dayElement.classList.add("datepicker-day-selected")
      }

      this.daysTarget.appendChild(dayElement)
    }
  }
}
