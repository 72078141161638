import { Controller } from "@hotwired/stimulus"
import Mark from "mark.js"

export default class extends Controller {
  static targets = [
    "select",
    "search",
    "icon",
    "option",
    "options",
    "emptyOption"
  ]

  static values = {
    selected: String
  }

  connect() {
    this.selectTarget.hidden = true

    // Used to highlight/mark matched text in results
    this.markInstance = new Mark(this.optionsTarget)

    this.forceHide()
    
    if (this.selectTarget.selectedIndex) {
      this.searchTarget.value = this.selectTarget.options[this.selectTarget.selectedIndex].text
    }

    this.emptyOptionTarget.hidden = true
  }

  show(event) {
    if (this.searchTarget.getAttribute("readonly") == "true") { return }

    this.searchTarget.setAttribute("aria-expanded", true)

    if (this.getVisibleOptions().length > 0) {
      if (this.optionsTarget.querySelector('[aria-selected="true"]') == null) {
        this.highlightOption(this.getVisibleOptions()[0])
      }

      this.emptyOptionTarget.hidden = true
    } else {
      this.emptyOptionTarget.hidden = false
    }

    this.optionsTarget.hidden = false

    this.iconTarget.innerHTML = "expand_less"

    this.resizeOptionsContainer()
  }

  // Because options container is using `position: fixed`, have to
  // update the width to match parent whenever screen resizes.
  resizeOptionsContainer() {
    const searchInputDimensions = this.searchTarget.getBoundingClientRect()
    this.optionsTarget.style.width = `${searchInputDimensions["width"]}px`
    this.optionsTarget.style.bottom = "auto"
    this.optionsTarget.style.top = `${searchInputDimensions["bottom"]}px`

    const optionsDimensions = this.optionsTarget.getBoundingClientRect()
    const viewportHeight = document.documentElement.offsetHeight
    const totalHeight = optionsDimensions.top + optionsDimensions.height

    if (totalHeight > viewportHeight) {
      let bottom = viewportHeight - searchInputDimensions["top"]
      this.optionsTarget.style.bottom = `${bottom}px`
      this.optionsTarget.style.top = "auto"
    }
  }

  // Wrapper for `forceHide()`, call `hide()` on external elements.
  hide(event) {
    // Do not hide if clicking within the autocomplete.
    if (this.element.contains(event.target) === false) {
      this.forceHide()
    }
  }

  // Does not check anything, but forces cancelation and hiding.
  forceHide() {
    this.searchTarget.setAttribute("aria-expanded", false)
    this.optionsTarget.hidden = true

    // Reset display text to actual selected text
    // this.searchTarget.value = this.selectTarget.getAttribute("data-text")
    if (this.selectTarget.options[this.selectTarget.selectedIndex]) {
      this.searchTarget.value = this.selectTarget.options[this.selectTarget.selectedIndex].text
    } else {
      this.searchTarget.value = null
    }

    if (this.hasIconTarget) {
      this.iconTarget.innerHTML = "expand_more"
    }

    this.showAllOptions()
    this.unmarkAllOptions()
  }

  get query() {
    return this.searchTarget.value.trim()
  }

  // Remove all text highligting in options
  unmarkAllOptions() {
    if (this.markInstance) {
      this.markInstance.unmark(this.optionsTarget)
    }
  }

  showAllOptions() {
    this.optionTargets.forEach((element) => {
      element.removeAttribute("hidden")
    })
  }

  deselectAllOptions() {
    this.optionTargets.forEach((element) => {
      element.setAttribute("aria-selected", false)
    })
  }

  getVisibleOptions() {
    return Array.from(this.optionsTarget.querySelectorAll('[role="option"]:not([hidden])'))
  }

  updateSelectionWithKeyboard(event) {
    switch (event.key) {
      case "Enter":
      case "Tab":
        if (!this.optionsTarget.hidden) {
          const optionToCommit = this.optionsTarget.querySelector('[aria-selected="true"]')
          this.commit(optionToCommit)

          event.stopPropagation()
          event.preventDefault()
        }

        break
      case "ArrowUp":
        if (this.optionsTarget.hidden) {
          this.show()
        } else {
          this.highlightPreviousOption()
        }

        event.stopPropagation()
        event.preventDefault()
        break
      case "ArrowDown":
        if (this.optionsTarget.hidden) {
          this.show()
        } else {
          this.highlightNextOption()
        }

        event.stopPropagation()
        event.preventDefault()
        break
      case "Escape":
        this.forceHide()

        event.stopPropagation()
        event.preventDefault()
        break
      case "Backspace":
        if (this.selectTarget.selectedIndex != -1) {
          this.selectedValue = ""
        }

        break
    }
  }

  filter(event) {
    this.unmarkAllOptions()
    this.showAllOptions()
    this.deselectAllOptions()

    // Changing text, so remove currently-selected value
    // this.selectTarget.setAttribute("data-text", "")
    this.selectTarget.selectedIndex = -1

    if (this.query.length > 0) {
      this.markInstance.mark(this.query, {
        separateWordSearch: true,
        done: () => {
          this.optionTargets.forEach((element) => {
            if (element.querySelector("mark") == null) {
              element.setAttribute("hidden", "")
            }
          })

          this.highlightOption(this.getVisibleOptions()[0])
        },
        exclude: [".list-item-icon *", ".material-icons *", ".button-icon *", ".skip-markjs *"]
      })
    }

    this.show()
  }

  commit(optionToCommit) {
    if (!optionToCommit) { return }

    this.selectedValue = optionToCommit.getAttribute("data-value")

    this.forceHide()
    this.searchTarget.focus()
  }

  onOptionClick(event) {
    const optionToCommit = event.currentTarget
    if (optionToCommit) {
      this.commit(optionToCommit)
    }
  }

  onMouseOver(event) {
    const optionToHighlight = event.currentTarget
    if (optionToHighlight) { this.highlightOption(optionToHighlight) }
  }

  highlightOption(optionToHighlight) {
    if (optionToHighlight == null) { return }

    this.deselectAllOptions()

    // Styles use this attribute to highlight
    optionToHighlight.setAttribute("aria-selected", true)
    optionToHighlight.scrollIntoView({ block: "nearest", inline: "nearest" })
  }

  highlightPreviousOption() {
    const options = this.getVisibleOptions()
    const selectedOption = this.optionsTarget.querySelector('[aria-selected="true"]')
    const index = options.indexOf(selectedOption)
    const previous = options[index - 1]

    if (previous) {
      this.highlightOption(previous)
    } else {
      this.highlightOption(options[options.length - 1])
    }
  }

  highlightNextOption() {
    const options = this.getVisibleOptions()
    const selectedOption = this.optionsTarget.querySelector('[aria-selected="true"]')
    const index = options.indexOf(selectedOption)
    const next = options[index + 1]

    if (next) {
      this.highlightOption(next)
    } else {
      this.highlightOption(options[0])
    }
  }

  selectedValueChanged(value) {
    this.selectTarget.value = value
    this.searchTarget.value = this.selectTarget.options[this.selectTarget.selectedIndex].text

    this.optionTargets.forEach((element) => {
      element.classList.remove("responsive-autocomplete-option-selected")
    })

    const selectedOption = this.optionsTarget.querySelector(`[data-value="${value}"]`)
    
    if (selectedOption) {
      selectedOption.classList.add("responsive-autocomplete-option-selected")
    }

    // To force validation from `form_controller` to execute
    const blurEvent = new Event("blur")
    this.searchTarget.dispatchEvent(blurEvent)
  }
}
