import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "textTemplateIdField",
    "textTemplateName",
    "removeTextTemplateButton"
  ]

  connect() {
    if (this.textTemplateIdFieldTarget.value.length > 0) {
      this.showRemoveButton()
      this.disableFields()
    } else {
      this.hideRemoveButton()
      this.enableFields()
    }
  }

  textTemplateIdFieldValueChanged(newValue) {
    console.log(this.textTemplateIdFieldValue.length)
    if (this.textTemplateIdFieldValue.length > 0) {
      this.disableFields()
      this.showRemoveButton()
    } else {
      this.enableFields()
      this.hideRemoveButton()
    }
  }

  showRemoveButton() {
    this.removeTextTemplateButtonTarget.hidden = false
  }

  hideRemoveButton() {
    this.removeTextTemplateButtonTarget.hidden = true
  }

  removeTextTemplate(event) {
    event.preventDefault()
    event.stopPropagation()

    this.textTemplateNameTarget.innerHTML = "No text template"
    this.textTemplateIdFieldTarget.value = ""

    this.removeTextTemplateButtonTarget.closest(".list-item").classList.toggle("bg-highlight")

    this.hideRemoveButton()
    this.enableFields()
  }

  disableFields() {
    document.getElementById("text_notification_subject").setAttribute("disabled", true)
    document.getElementById("text_notification_body").setAttribute("data-html-editor-disabled-value", true)
  }

  enableFields() {
    console.log("enb")
    document.getElementById("text_notification_subject").removeAttribute("disabled")
    document.getElementById("text_notification_body").setAttribute("data-html-editor-disabled-value", false)
  }
}
