import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "teamMemberType", "userId" ]

  initialize() {
    if (this.hasTeamMemberTypeTarget) {
      new  App.MD.Combobox(this.teamMemberTypeTarget)
    }

    if (this.hasUserIdTarget) {
      new  App.MD.Combobox(this.userIdTarget)
    }
  }
}
