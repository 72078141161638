import { Controller } from "@hotwired/stimulus"
import AutoNumeric from "autonumeric"
import IMask from "imask"

export default class extends Controller {
  static values = {
    format: String
  }

  connect() {
    switch (this.formatValue) {
      case "currency":
      case "currency-allow-negative":
        this.element.setAttribute("placeholder", "$ ")
      case "percentage":
      case "percentage-allow-negative":
        this.element.setAttribute("placeholder", "%")
        this.autonumeric = new AutoNumeric(this.element, this.getAutoNumericOptions())
        break
      case "phone":
        this.imask = IMask(this.element, this.getImaskOptions())
        break
    }
  }

  disconnect() {
    if (this.autonumeric) {
      this.autonumeric.remove()
    }

    if (this.imask) {
      this.imask.destroy()
    }
  }

  // setPlaceholderIfApplicable() {
  //   switch (this.formatValue) {
  //     case "currency":
  //     case "currency-allow-negative":
  //       this.element.setAttribute("placeholder", "$ ")
  //       break
  //     case "percentage":
  //     case "percentage-allow-negative":
  //       this.element.setAttribute("placeholder", "%")
  //       break
  //   }
  // }

  getAutoNumericOptions() {
    let options = {}

    switch (this.formatValue) {
      case "currency":
        options = {
          currencySymbol: "$ ",
          digitGroupSeparator: ",",
          decimalCharacter: ".",
          minimumValue: "0",
          maximumValue: "999999999999.99",
          digitalGroupSpacing: "3",
          allowDecimalPadding: true
        }

        break
      case "percentage":
        options = {
          currencySymbol: " %",
          currencySymbolPlacement: "s",
          minimumValue: "0",
          maximumValue: "100.00",
          decimalPlaces: 3,
          allowDecimalPadding: true
        }

        break
      case "percentage-allow-negative":
        options = {
          currencySymbol: " %",
          currencySymbolPlacement: "s",
          minimumValue: "-100.00",
          maximumValue: "100.00",
          decimalPlaces: 3,
          allowDecimalPadding: true
        }

        break
      case "numerical":
        options = {
          minimumValue: "0",
          maximumValue: "999999999",
          decimalPlaces: 0
        }

        break
    }

    return options
  }

  getImaskOptions() {
    let options = {}

    switch (this.formatValue) {
      case "phone":
        options = {
          mask: "(000) 000-0000",
          lazy: false,
          placeholderChar: "_"
        }

        break
    }

    return options
  }
}
