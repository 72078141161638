import { Controller } from "@hotwired/stimulus"
import HtmlEditor from "components/html_editor"

export default class extends Controller {
  static targets = [
    "email",
    "phone",
    "comment",
    "referredBy",
    "referralSource"
  ]

  initialize() {
    Masks.init()

    if (document.getElementById("lead_comment")) {
      new HtmlEditor(this.commentTarget, {
        placeholderText: "Add a comment...",
        heightMin: 100
      })
    }
  }

  referralAutocomplete() {
    $("#lead_referred_by").autoComplete({
      source: function(term, response) {
        term = term.toLowerCase()

        $.get("/autocompletables/referrals", { q: term }, function(data) {
          let matches = []
          let records = data.results

          for (let i = 0; i < records.length; i++) {
            let record = records[i]
            let text = record.text.toLowerCase()

            if (fuzzysearch(term, text)) {
              matches.push(record)
            }
          }

          response(matches)
        })
      },
      minChars: 1,
      limit: 3,
      delay: 150,
      cache: false,
      menuClass: "list",
      renderItem: (item, search) => {
        search = search.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&")
        let regex = new RegExp(`(${search.split(" ").join("|")})`, "gi")

        return `
          <div class="autocomplete-suggestion list-item list-two-lines" data-id="${item.id}" data-value="${item.text}">
            <div class="list-item-primary">
              ${item.text.replace(regex, "<b>$1</b>")}
              <div class="list-item-primary-subtext">
                ${item.record_type}
              </div>
            </div>
          </div>
        `
      },
      onSelect: (event, term, item) => {
        let name = item.data("value")

        // this.clientIdTarget.value = item.data("id")
        this.referredByTarget.value = name

        event.preventDefault()
        event.stopPropagation()
      }
    })
  }
}
