import { Controller } from "@hotwired/stimulus"
import Tribute from "tributejs"

export default class extends Controller {
  static targets = []

  static values = {
    url: String
  }

  connect() {
    // this.tribute = new Tribute({
    //   trigger: "#",
    //   values: []
    // })

    fetch(this.urlValue)
      .then((response) => { return response.json() })
      .then((data) => {
        this.tribute = new Tribute({
          trigger: "#",
          values: data,
          lookup: "title",
          fillAttr: "title",
          spaceSelectsMatch: true,
          selectTemplate: function(item) {
            return item.original.title
          }
        })

        this.tribute.attach(this.element)
      })
  }

  disconnect() {
    this.tribute.detach(this.element)
  }
}

// fetch(`${this.urlValue}?q=${this.query}`)
//   .then((response) => { return response.text() })
//   .then((html) => {
//     this.optionsTarget.innerHTML = html
//
//     this.selectFirstOption()
//
//     this.markInstance = new Mark(this.optionTargets)
//
//     this.markInstance.mark(this.query, {
//       separateWordSearch: false,
//       exclude: [".list-item-icon", ".material-icons", ".button-icon", ".skip-markjs"]
//     })
//
//     this.show()
//   })
//   .catch((error) => {
//     console.error(error)
//     this.forceHide()
//   })
