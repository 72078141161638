import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "completedAt" ]
  initialize() {
    Masks.init()

    if (this.hasCompletedAtTarget) {
      new Datepicker(this.completedAtTarget)
    }
  }
}
