import { Controller } from "@hotwired/stimulus"
import Mousetrap from "mousetrap"

export default class extends Controller {
  static values = {
    key: String,
    description: String,
    url: String
  }

  connect() {
    if (!this.hasKeyValue) { return }

    const url = this.urlValue || this.element.getAttribute("href")

    // Native JS `click()` trigger will NOT execute on links,
    // so those have to be dealt with different than other elements.
    Mousetrap.bind(this.keyValue.split(","), (_event) => {
      document.querySelectorAll(".menu").forEach((element) => {
        element.classList.remove("menu-open")
      })

      if (url) {
        // For Rails remote URLs
        if (this.element.hasAttribute("data-remote")) {
          this.element.click()
        } else {
          // For standard non-remote URLs
          Turbolinks.visit(url)
        }
      } else {
        if (this.element instanceof HTMLInputElement) {
          this.element.focus()
        } else {
          this.element.click()
        }
      }
    }, "keyup")
  }

  disconnect() {
    Mousetrap.unbind(this.keyValue.split(","), "keyup")
  }
}
